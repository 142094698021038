import React from 'react';

import SocialIcon from 'components/social/SocialIcon';
import { APP_STORE_LINKS } from 'lib/constants/mobileApp';

// eslint-disable-next-line import/no-unresolved, import/no-webpack-loader-syntax
import DownloadAndroidSvg from '!!@svgr/webpack!images/download_android.svg';
// eslint-disable-next-line import/no-unresolved, import/no-webpack-loader-syntax
import DownloadIosSvg from '!!@svgr/webpack!images/download_ios.svg';

export { DownloadAndroidSvg, DownloadIosSvg };

const DownloadAppLink = ({ href, Svg, label, ...rest }) => {
  return (
    <a href={href} target="_blank" rel="noreferrer" aria-label={label} {...rest}>
      <Svg className="is-block" aria-hidden />
      <style jsx>{`
        a {
          :global(svg) {
            transition: fill 300ms ease;
          }
          &:hover,
          &:focus {
            :global(svg) {
              fill: #222;
            }
          }
        }
      `}</style>
    </a>
  );
};

export const DownloadIosLink = (props) => (
  <DownloadAppLink
    href={APP_STORE_LINKS.ios}
    label="Download on the App Store"
    Svg={DownloadIosSvg}
    {...props}
  />
);

export const DownloadAndroidLink = (props) => (
  <DownloadAppLink
    href={APP_STORE_LINKS.android}
    label="Get it on Google Play"
    Svg={DownloadAndroidSvg}
    {...props}
  />
);

const links = [
  { icon: 'facebook', link: 'https://facebook.com/vanlyapp' },
  { icon: 'twitter', link: 'https://twitter.com/vanlyapp' },
  // { icon: 'linkedin', link: 'https://linkedin.com/company/vanly' },
  { icon: 'instagram', link: 'https://instagram.com/vanlyapp' },
  { icon: 'youtube', link: 'https://youtube.com/channel/UCV4SrPYdBhr5-c3b5xy4jLg' },
];

const SocialLinks = ({ className }) => (
  <div className={className}>
    {links.map(({ icon, link }) => (
      <SocialIcon
        key={icon}
        component="a"
        icon={icon}
        href={link}
        className="button is-inverted is-dark rounded-sm"
        target="_blank"
        rel="noopener noreferrer"
        title={icon}
      />
    ))}

    <style jsx>{`
      div > :global(.button:not(:hover)) {
        background-color: transparent;
      }
    `}</style>
  </div>
);

export default SocialLinks;
