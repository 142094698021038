import React, { useMemo } from 'react';
import clsx from 'clsx';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';

import { useRouter } from 'lib/hooks/router';
import routeResolver from 'config/routes.mjs';
import { useIsMobile } from 'components/Responsive';
import { HOST, IS_SSR, MOBILE_APP } from 'lib/env';
import { urlPathname } from 'lib/utils/url';

/**
 * There is currently a bug (on dev server atleast), where the
 * rendered anchor tag, i.e. <a href="..."/>, has the *original*
 * `href` even if the `href` is changed when the page mounts. The link
 * works correctly, i.e. it navigates with the *current* `href`,
 * but the actual `<a/>` DOM element shows the wrong, i.e. the original, `href`
 */

export type LinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement> &
  NextLinkProps & {
    route?: string;
    params?: Record<string, any>;
    newTab?: boolean;
  };

const ABS_URL = /^((https?:)?\/\/|mailto:|tel:)/;
const getUrls = (
  route?: string,
  href?: string,
  params?: Record<string, any>,
  isMobile?: boolean,
  newTab?: boolean,
  providedPrefetch?: boolean,
) => {
  // don't run routeResolver on external urls
  if (href != null && ABS_URL.test(href) && !href.startsWith(HOST)) {
    return {
      href,
      as: undefined,
      anchorProps: newTab ? { target: '_blank', rel: 'noopener noreferrer' } : {},
      prefetch: false,
      nativeLink: true,
    };
  }

  // TODO: links starting with /api/ would need custom handling on mobile-app
  // if (href?.startsWith('/api/')) {
  //   return {
  //     href,
  //     as: undefined,
  //     anchorProps: newTab ? { target: '_blank' } : {},
  //     prefetch: false,
  //     nativeLink: true,
  //   };
  // }

  return {
    ...routeResolver.findAndGetUrls(route || href, params).urls,
    // don't open new tabs on mobile
    anchorProps: !isMobile && !MOBILE_APP && newTab ? { target: '_blank' } : {},
    prefetch: providedPrefetch,
  };
};

const Link: React.FC<LinkProps> = ({
  href: providedHref,
  route,
  params,
  newTab,

  // <NextLink/> props
  prefetch: providedPrefetch,
  replace,
  scroll,

  // <a/> props
  children,
  ...rest
}) => {
  const isMobile = useIsMobile();

  if (!route && !providedHref) {
    throw new Error('Missing Link params');
  }

  const { href, as, anchorProps, prefetch, nativeLink } = getUrls(
    route,
    providedHref,
    params,
    isMobile,
    newTab,
    providedPrefetch,
  );

  return nativeLink ? (
    <a {...anchorProps} href={href} {...rest}>
      {children}
    </a>
  ) : (
    <NextLink
      {...anchorProps}
      {...rest}
      prefetch={prefetch}
      replace={replace}
      scroll={scroll}
      href={href}
      as={as}
    >
      {children}
    </NextLink>
  );
};

export const NavLink: React.FC<
  Omit<LinkProps, 'newTab'> & {
    exact?: boolean;
    isActive?: (currentPathname: string, linkPathname: string) => boolean;
  }
> = ({
  href: providedHref,
  route,
  params,

  // NavLink custom props
  exact,
  isActive,

  // <NextLink/> props
  prefetch,
  replace,
  scroll,

  // <a/> props
  children,
  className,
  ...rest
}) => {
  const router = useRouter();

  const { href, as } = getUrls(route, providedHref, params);

  const active = useMemo(() => {
    if (!as) return false;

    let currentPathname = urlPathname(router.asPath);

    // fixes a bug when rendering NavLink server-side. TODO: what's the bug?
    if (IS_SSR && href !== as && router.asPath === router.pathname) {
      const realAs = routeResolver.routes
        .find((r: { page: string }) => r.page === router.pathname)
        ?.getAs(router.query);
      if (realAs) currentPathname = realAs;
    }

    const linkPathname = urlPathname(as);

    if (isActive) return isActive(currentPathname, linkPathname);

    return exact ? currentPathname === linkPathname : currentPathname.startsWith(linkPathname);
  }, [router, href, as, exact]);

  return (
    <NextLink
      prefetch={prefetch}
      replace={replace}
      scroll={scroll}
      href={href}
      as={as}
      className={clsx(active && 'is-active', className)}
      {...rest}
    >
      {children}
    </NextLink>
  );
};

export default Link;
