import * as _ from 'lodash-es';

export const LISTING_TITLE_LENGTH = { min: 5, max: 50 };
export const LISTING_DESCRIPTION_LENGTH = { min: 3, max: 15000 };

// reviews are integers in range [0, 4]
export const MAX_REVIEW = 4;

export const LISTING_PRICE = { min: 1, max: 10000 };

export const RANDOMIZE_LOCATION_METERS = { min: 600, max: 800 };

// ---

export const NIGHTS_MIN_OPTIONS = [
  { key: 1, label: '1 night' },
  { key: 2, label: '2 nights' },
  { key: 3, label: '3 nights' },
  { key: 4, label: '4 nights' },
  { key: 5, label: '5 nights' },
  { key: 6, label: '6 nights' },
  { key: 7, label: '1 week' },
  { key: 14, label: '2 weeks' },
  { key: 30, label: '1 month' },
  { key: 90, label: '3 months' },
  { key: 180, label: '6 months' },
  { key: 270, label: '9 months' },
  { key: 365, label: '1 year' },
];

export const NIGHTS_MAX_OPTIONS = [{ key: null, label: 'No limit' }, ...NIGHTS_MIN_OPTIONS];

export const BOOKING_NOTICE_LIMIT_OPTIONS = [
  { key: 0, label: 'Same day' },
  { key: 1, label: '1 day' },
  { key: 2, label: '2 days' },
  { key: 3, label: '3 days' },
  { key: 7, label: '7 days' },
];

export const HOW_FAR_CAN_GUESTS_BOOK_OPTIONS = [
  { key: null, label: 'No restriction' },
  { key: 7, label: '1 week' },
  { key: 14, label: '2 weeks' },
  { key: 30, label: '1 month' },
  { key: 90, label: '3 months' },
  { key: 180, label: '6 months' },
  { key: 365, label: '1 year' },
];

// matches `./amenityIcons.ts`
export const AMENITIES_OPTIONS = [
  { key: 'bathroom', label: 'Bathroom', icon: 'fa-toilet' },
  { key: 'water', label: 'Water', icon: 'fa-water' },
  { key: 'wifi', label: 'Wifi', icon: 'fa-wifi' },
  { key: 'electricity', label: 'Electricity', icon: 'fa-plug' },
  { key: 'kitchen', label: 'Kitchen', icon: 'fa-utensils' },
  { key: 'shower', label: 'Shower', icon: 'fa-shower' },
  { key: 'laundry', label: 'Laundry', icon: 'fa-shirt' },
  { key: 'firepit', label: 'Firepit', icon: 'fa-fire' },
  { key: 'hot_tub', label: 'Hot Tub', icon: 'fa-hot-tub-person' },
  { key: 'office_space', label: 'Office Space', icon: 'fa-briefcase' },
  { key: 'septic', label: 'Septic System', icon: 'fa-poo' },
  { key: 'pool', label: 'Pool', icon: 'fa-person-swimming' },
  { key: 'pet', label: 'Pet Play Space', icon: 'fa-dog' },
  { key: 'tools', label: 'Tools', icon: 'fa-screwdriver-wrench' },
  { key: 'trash', label: 'Garbage', icon: 'fa-trash' },
  { key: 'packages', label: 'Package Delivery', icon: 'fa-truck-fast' },
] as const;

export const AMENITIES_LABELS = _.mapValues(
  _.keyBy(AMENITIES_OPTIONS, (d) => d.key),
  (d) => d.label,
);

export const AMENITIES = AMENITIES_OPTIONS.map((amenity) => amenity.key);

export type AmenityKey = typeof AMENITIES_OPTIONS[number]['key'];

export const LISTING_TYPES = [
  { key: 'driveway', label: 'Driveway' },
  { key: 'land', label: 'Private Land' },
  { key: 'garage', label: 'Garage' },
] as const;

export type ListingTypeKey = typeof LISTING_TYPES[number]['key'];

export const CANCELLATION_POLICIES = [
  {
    key: 'flexible',
    label: 'Flexible',
    desc: 'The guest can cancel up to 24 hours before the scheduled date, and are eligible for a full-refund minus the service fee.',
    className: 'is-success',
    refundRatio: 1,
    cancelMaxDaysBefore: 1,
  },
  {
    key: 'moderate',
    label: 'Moderate',
    desc: 'The guest can cancel their booking up to three days before their scheduled stay and are eligible for a 50% refund minus the service fee.',
    className: 'is-warning',
    refundRatio: 0.5,
    cancelMaxDaysBefore: 3,
  },
  {
    key: 'strict',
    label: 'Strict',
    desc: 'All bookings are final, and no refunds are awarded to no-shows.',
    className: 'is-danger',
    refundRatio: null,
    cancelMaxDaysBefore: null,
  },
] as const;

export type CancellationPolicyType = typeof CANCELLATION_POLICIES[number]['key'];

export const CANCELLATION_POLICIES_MAP = _.keyBy(CANCELLATION_POLICIES, (d) => d.key);

// We assume if a rule is contained in
// listing.rules, then it is allowed
// Everything else should be a negation
export const LISTING_RULES = [
  {
    key: 'EW',
    label: 'Engine warm-up',
    yesIcon: 'fa-temperature-high',
    noIcon: 'fa-temperature-high',
  },
  { key: 'IN', label: 'Infants', yesIcon: 'fa-baby', noIcon: 'fa-baby' },
  { key: 'PE', label: 'Pets', yesIcon: 'fa-paw', noIcon: 'fa-paw' },
  { key: 'SM', label: 'Smoking', yesIcon: 'fa-smoking', noIcon: 'fa-smoking-ban' },
  { key: 'FP', label: 'Fire pits', yesIcon: 'fa-fire', noIcon: 'fa-fire' },
  { key: 'EP', label: 'Events or Parties', yesIcon: 'fa-glass-cheers', noIcon: 'fa-glass-cheers' },
] as const;

export type ListingRuleKey = typeof LISTING_RULES[number]['key'];

export const GROUND_ANGLE_OPTIONS = [
  { key: 'flat', label: 'Flat ground' },
  { key: 'medium', label: 'Mostly flat ground' },
  { key: 'steep', label: 'Steep ground' },
] as const;

export const GROUND_ANGLE_MAP = _.keyBy(GROUND_ANGLE_OPTIONS, (d) => d.key);

export type GroundAngleType = typeof GROUND_ANGLE_OPTIONS[number]['key'];
