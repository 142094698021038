import type adminStorage from '@google-cloud/storage';
import type firebase from 'firebase/compat/app';

import db from 'lib/db/shared';
import { HOST, IS_SSR } from 'lib/env';
import { getListingSatelliteImage } from 'lib/geo';
import type {
  ChatMessageDoc,
  ListingDoc,
  ListingReviewDoc,
  ProfileDoc,
  WithId,
} from 'schemas/types';

import { trackError } from './tracking';

const { buckets } = db;

export const AVATAR_IMAGE_DEFAULT = `${HOST}/static/avatar.svg`;
export const LISTING_IMAGE_DEFAULT = `${HOST}/static/driveway-placeholder.jpg`;

// NOTE: images aren't exactly these width x height, it just defines the image's maximum width and height

export const AVATAR_IMAGE_SIZES = {
  original: 'original', // do not use
  default: 'original_512x512',
  thumb: 'original_200x200',
};

export enum ReviewFileType {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
}

export const AVATAR_IMAGE_SIZE_MAP: Record<string, { width: number; height: number }> = {
  default: { width: 512, height: 512 },
  thumb: { width: 200, height: 200 },
};

export type AvatarImageSize = keyof typeof AVATAR_IMAGE_SIZES;

export const LISTING_IMAGE_SIZES = {
  original: 'original', // do not use
  default: 'original_900x600',
  thumb: 'original_200x200',
};

export const LISTING_IMAGE_SIZE_MAP: Record<string, { width: number; height: number }> = {
  default: { width: 900, height: 600 },
  thumb: { width: 200, height: 200 },
};

export type ListingImageSize = keyof typeof LISTING_IMAGE_SIZES;

const buildImageUrl = (type: keyof typeof buckets, path: string): string => {
  const bucket = buckets[type];
  const bucketName = IS_SSR
    ? (bucket as adminStorage.Bucket).name
    : (bucket as firebase.storage.Reference).bucket;

  return `https://${bucketName}.storage.googleapis.com/${path}`;
};

export const buildAvatarImageUrl = (
  userId: string,
  imageId: string,
  size: AvatarImageSize,
): string => buildImageUrl('avatarImages', `${userId}/${imageId}/${AVATAR_IMAGE_SIZES[size]}`);

export const buildListingImageUrl = (
  userId: string,
  listingId: string,
  imageId: string,
  size: ListingImageSize,
): string =>
  buildImageUrl('listingImages', `${userId}/${listingId}/${imageId}/${LISTING_IMAGE_SIZES[size]}`);

export const buildListingReviewFileUrl = (
  userId: string,
  listingId: string,
  reviewId: string,
  fileId: string,
  size: ListingImageSize,
): string =>
  buildImageUrl(
    'listingReviewFiles',
    `${userId}/${listingId}/${reviewId}/${fileId}/${LISTING_IMAGE_SIZES[size]}`,
  );

export const avatarImageUrl = (
  profile: WithId<ProfileDoc>,
  size: AvatarImageSize = 'default',
): string => {
  if (profile.id && profile.avatarId)
    return buildAvatarImageUrl(
      profile.id,
      profile.avatarId,
      // if resize operation failed, render the `original` image
      profile.fdrz ? 'original' : size in AVATAR_IMAGE_SIZES ? size : 'default',
    );
  else return AVATAR_IMAGE_DEFAULT;
};

const listingFallbackImage = {
  src: LISTING_IMAGE_DEFAULT,
  width: 649,
  height: 485,
};

export const listingImageData = (
  listing: WithId<ListingDoc>,
  {
    size = 'default',
    index = 0,
    gmapFallback = false,
  }: { size?: ListingImageSize; index?: number; gmapFallback?: boolean } = {},
): {
  src: string;
  width?: number;
  height?: number;
  blurDataURL?: string;
  fallbackSrc?: string | { src: string; width?: number; height?: number };
} => {
  const imageMeta = listing.photos?.[index];
  if (imageMeta) {
    if (listing.id && listing.owner?.id && imageMeta.id) {
      // if resize operation failed, render the `original` image

      if (imageMeta.fdrz) size = 'original';

      const dim = LISTING_IMAGE_SIZE_MAP[size] || {};

      return {
        src: buildListingImageUrl(listing.owner.id, listing.id, imageMeta.id, size),
        blurDataURL: imageMeta.blurDataUrl,
        ...dim,
        fallbackSrc: listingFallbackImage,
      };
    } else {
      trackError('missing listing data for listingImageUrl', { listing, index, size });
    }
  } else if (gmapFallback && listing.geocoords) {
    return {
      ...getListingSatelliteImage(listing.geocoords, size),
      fallbackSrc: listingFallbackImage,
    };
  }

  return listingFallbackImage;
};

export const listingImageUrl = (...args: Parameters<typeof listingImageData>): string =>
  listingImageData(...args).src;

export const getListingGalleryImages = (listing: WithId<ListingDoc>) => {
  if (listing.photos?.length)
    return listing.photos.map((p, i) => {
      const image = listingImageData(listing, { index: i });

      return {
        index: i,
        originalImage: image,
        original: image.src,
        description: p.description,
        originalTitle: p.description,
        originalAlt: p.description,
      };
    });

  const satelliteImage = getListingSatelliteImage(listing.geocoords);

  return [
    {
      index: 0,
      originalImage: { ...satelliteImage, fallbackSrc: listingFallbackImage },
      original: satelliteImage.src,
      description:
        'This photo was automatically generated. We cannot guarantee this is an accurate representation of the listing.',
      originalTitle: '',
      originalAlt: 'Google Satellite for the listing',
    },
  ];
};

export const chatImageUrl = (
  chatId: string,
  fileMeta: NonNullable<ChatMessageDoc['files']>[number],
) => {
  return buildImageUrl('messageUploads', `chats/${chatId}/${fileMeta.id}/original`);
};

export const getListingReviewFiles = (review: WithId<ListingReviewDoc>) => {
  if (review.files?.length) {
    return review.files.map((file) => ({
      src: buildListingReviewFileUrl(
        review.user_id,
        review.listing_id,
        review.id,
        file.id,
        'original',
      ),
      type: file.type.startsWith('video') ? ReviewFileType.VIDEO : ReviewFileType.IMAGE,
    }));
  }
};
