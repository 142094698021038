import { useEffect } from 'react';
import { Keyboard } from '@capacitor/keyboard';
import type { PluginListenerHandle } from '@capacitor/core';
import type { ConnectionType } from '@capacitor/network';
import { App as CapApp } from '@capacitor/app';
import { StatusBar, Style as StatusBarStyle } from '@capacitor/status-bar';

import { createObserver, useObserver } from 'lib/utils/async';
import { MOBILE_APP, PLATFORM } from 'lib/env';
import { NOOP } from 'lib/empty';

type KbState = { state: 'start_showing' | 'showing' | 'start_hiding' | 'hiding'; height: number };
const DEFAULT_KEYBOARD_STATE: KbState = { state: 'hiding', height: 0 };

// observe keyboard events globally (as opposed to in a react hook) to avoid constantly
// adding & removing listeners when `Layout` unmounts on every page change
const keyboardObserver =
  MOBILE_APP && PLATFORM !== 'web' && Keyboard
    ? createObserver<KbState>(
        DEFAULT_KEYBOARD_STATE,
        ({ next }) => {
          const handles: (PluginListenerHandle & Promise<PluginListenerHandle>)[] = [];

          handles.push(
            Keyboard.addListener('keyboardWillShow', (info) => {
              next({ state: 'start_showing', height: info.keyboardHeight });
            }),
          );

          handles.push(
            Keyboard.addListener('keyboardDidShow', (info) => {
              next({ state: 'showing', height: info.keyboardHeight });
            }),
          );

          handles.push(
            Keyboard.addListener('keyboardWillHide', () => {
              next({ state: 'start_hiding', height: 0 });
            }),
          );

          handles.push(
            Keyboard.addListener('keyboardDidHide', () => {
              next({ state: 'hiding', height: 0 });
            }),
          );

          return () => {
            for (const handle of handles) handle.remove();
          };
        },
        true,
      )
    : null;

export const useKeyboardState = () => {
  const { state, height } = useObserver(keyboardObserver) || DEFAULT_KEYBOARD_STATE;

  return { isActive: state === 'showing' || state === 'start_showing', state, height };
};

type NetworkState = { connectionType: ConnectionType; connected: boolean };
const DEFAULT_NETWORK_STATE: NetworkState = { connectionType: 'unknown', connected: true };

// observe network events globally (as opposed to in a react hook) to avoid constantly
// adding & removing listeners when `Layout` unmounts on every page change
const networkObserver = MOBILE_APP
  ? createObserver<NetworkState>(
      DEFAULT_NETWORK_STATE,
      ({ next }) => {
        const handles: (PluginListenerHandle & Promise<PluginListenerHandle>)[] = [];

        import('@capacitor/network').then(({ Network }) => {
          handles.push(
            Network.addListener('networkStatusChange', (status) => {
              next({ connectionType: status.connectionType, connected: status.connected });
            }),
          );
        });

        return () => {
          for (const handle of handles) handle.remove();
        };
      },
      true,
    )
  : null;

export const useConnectionState = () => {
  const { connectionType, connected } = useObserver(networkObserver) || DEFAULT_NETWORK_STATE;

  return { connectionType, connected };
};

const DEFAULT_APP_STATE = { isActive: true };
const appStateObserver = MOBILE_APP
  ? createObserver<typeof DEFAULT_APP_STATE>(
      DEFAULT_APP_STATE,
      ({ next }) => {
        const handles: (PluginListenerHandle & Promise<PluginListenerHandle>)[] = [];

        handles.push(
          CapApp.addListener('appStateChange', (appState) => {
            next({ isActive: appState.isActive });
          }),
        );

        return () => {
          for (const handle of handles) handle.remove();
        };
      },
      true,
    )
  : null;

export const useAppState = () => {
  const { isActive } = useObserver(appStateObserver) || DEFAULT_APP_STATE;

  return { isActive };
};

const statusBarStack: { id: string; previous: { style: StatusBarStyle; color?: string } | null }[] =
  [];

const DEFAULT_BG_COLORS = {
  dark: '#181818',
  light: '#ffffff',
};

export const useStatusBarStyle = MOBILE_APP
  ? (style: 'dark' | 'light' | null, bgColor = style ? DEFAULT_BG_COLORS[style] : null) => {
      useEffect(() => {
        if (!style && !bgColor) return;

        const id = Math.random().toString().substring(2);

        statusBarStack.push({ id, previous: null });
        StatusBar.getInfo().then((info) => {
          const i = statusBarStack.findIndex((d) => d.id === id);
          if (i === -1 || i !== statusBarStack.length - 1) return;

          statusBarStack[i].previous = { style: info.style, color: info.color };

          if (bgColor && PLATFORM === 'android')
            StatusBar.setBackgroundColor({
              color: bgColor,
            });
          if (style)
            StatusBar.setStyle({
              style: style === 'dark' ? StatusBarStyle.Dark : StatusBarStyle.Light,
            });
        });

        return () => {
          const i = statusBarStack.findIndex((d) => d.id === id);
          if (i === -1) return; // how the heck?

          const prev = statusBarStack[i].previous;
          const isLast = i === statusBarStack.length - 1;

          statusBarStack.splice(i, 1);

          if (isLast && prev) {
            StatusBar.setStyle({
              style: prev.style,
            });
            if (prev.color != null && PLATFORM === 'android')
              StatusBar.setBackgroundColor({
                color: prev.color,
              });
          }
        };
      }, [style, bgColor]);
    }
  : NOOP;
