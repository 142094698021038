import React from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';

import { HOST, MOBILE_APP } from 'lib/env';
import { absUrl } from 'lib/utils/url';

const MetaGraph = ({ title, description, keywords, article, image }) => {
  const pathname = useRouter()?.asPath || '';

  if (MOBILE_APP) return null;

  if (image && typeof image === 'string') image = { src: image };
  if (image) image.src = absUrl(image.src);

  let type = 'website';
  let publishedTime, modifiedTime;
  if (article) {
    type = 'article';
    publishedTime = article.published_time?.toISOString();
    modifiedTime = article.modified_time?.toISOString() || publishedTime;
  }

  return (
    <Head>
      <meta key="og:type" property="og:type" content={type} />
      <meta key="og:url" property="og:url" content={HOST + pathname} />

      {keywords ? <meta key="keywords" name="keywords" content={keywords.join(',')} /> : null}
      {description ? <meta key="description" name="description" content={description} /> : null}
      {description ? (
        <meta key="og:description" property="og:description" content={description} />
      ) : null}
      {description ? (
        <meta key="twitter:description" name="twitter:description" content={description} />
      ) : null}
      {publishedTime ? (
        <meta
          key="article:published_time"
          property="article:published_time"
          content={publishedTime}
        />
      ) : null}
      {modifiedTime ? (
        <meta key="article:modified_time" property="article:modified_time" content={modifiedTime} />
      ) : null}
      {image?.src ? <meta key="og:image" property="og:image" content={image.src} /> : null}
      {image?.width ? (
        <meta key="og:image:width" property="og:image:width" content={image.width} />
      ) : null}
      {image?.height ? (
        <meta key="og:image:height" property="og:image:height" content={image.height} />
      ) : null}
      {image?.src ? <meta key="twitter:image" name="twitter:image" content={image.src} /> : null}
      {title ? <meta key="og:title" property="og:title" content={title} /> : null}
      {title ? <meta key="twitter:title" name="twitter:title" content={title} /> : null}
      {title ? <title key="title">{title}</title> : null}
    </Head>
  );
};

export default MetaGraph;
