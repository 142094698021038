import { now } from 'lib/utils/time';
import { C } from 'lib/db/shared';
import { getDoc } from 'lib/utils/firestore';
import { AVATAR_IMAGE_DEFAULT } from 'lib/images';
import type { ProfileDoc, WithId } from 'schemas/types';

export const missingPublicProfile = (userId: string): WithId<ProfileDoc> => ({
  id: userId,
  name: '<account_deleted>',
  firstName: '<account_deleted>',
  lastName: '',
  bio: '',
  avatar: null,
  avatarId: null,
  verified_email: false,
  verified_govid: false,
  verified_phone: false,
  default_vehicle: null,
  created: now(),
});

export const getPublicProfile = async (
  userId: string,
  options: { fail?: boolean; returnDefaults?: boolean } = {},
) => {
  options.fail ??= false;
  options.returnDefaults ??= true;

  let profile = await getDoc(C.profiles.doc(userId), options);

  if (!profile) {
    profile = missingPublicProfile(userId);
  }

  if (options.returnDefaults) {
    profile.avatar ||= AVATAR_IMAGE_DEFAULT;
    profile.name ||= '-';
    profile.firstName ||= '-';
  }

  return profile;
};
