import React, { useState, useCallback } from 'react';
import clsx from 'clsx';

import { useRouterEvent } from 'lib/hooks/router';
import Link from 'components/Link';
import Logo from 'components/Logo';
import useAtTop from 'lib/hooks/useAtTop';

export const NavMenu = ({ trigger, children }) => {
  return (
    <div className="navbar-item has-dropdown is-hoverable">
      {typeof trigger === 'string' ? <div className="navbar-link">{trigger}</div> : trigger}
      <div className="navbar-dropdown is-right is-boxed">{children}</div>
    </div>
  );
};

const BaseNav = ({
  topShadow = true,
  topDark = false,
  topTransparent = false,
  bottomShadow = true,
  bottomDark = false,
  brandDisabled = false,
  hideLogo = false,
  navbarStart,
  navbarEnd,
  navbarBrand,
}) => {
  const [open, setOpen] = useState(false);
  const atTop = useAtTop(20);

  const close = useCallback(() => setOpen(false), []);
  useRouterEvent('routeChangeStart', close);

  const toggle = () => setOpen((current) => !current);

  const isLight = open || (atTop ? !(topDark || topTransparent) : !bottomDark);
  const showShadow = atTop ? topShadow : bottomShadow;

  const renderProps = {
    topShadow,
    topDark,
    topTransparent,
    bottomShadow,
    bottomDark,
    showShadow,
    isLight,
  };
  const renderPart = (part) => {
    return (typeof part === 'function' ? part(renderProps) : part) || null;
  };
  navbarBrand = renderPart(navbarBrand);
  navbarStart = renderPart(navbarStart);
  navbarEnd = renderPart(navbarEnd);

  const hasMenu = !!(navbarStart || navbarEnd);

  return (
    <>
      <nav
        className={clsx(
          'navbar',
          showShadow && 'has-shadow',
          !isLight && 'is-link',
          !open && topTransparent && atTop && 'is-transparent',
        )}
      >
        <div className={clsx('navbar-brand', !hasMenu && 'flex-1')}>
          {!hideLogo &&
            (brandDisabled ? (
              <div className="navbar-item">
                <Logo size={48} />
              </div>
            ) : (
              <Link className="navbar-item" href="/">
                <span className="sr-only">Vanly</span>
                <Logo size={48} />
              </Link>
            ))}
          {navbarBrand}
          {hasMenu ? (
            <button
              type="button"
              className={clsx('navbar-burger burger', open && 'is-active')}
              onClick={toggle}
              tabIndex={0}
              aria-label="menu"
            >
              <span aria-hidden="true" />
              <span aria-hidden="true" />
              <span aria-hidden="true" />
            </button>
          ) : null}
        </div>
        {hasMenu ? (
          <div className={`navbar-menu ${open ? 'is-active' : ''}`}>
            <div className="navbar-start">{navbarStart}</div>
            <div className="navbar-end">{navbarEnd}</div>
          </div>
        ) : null}
      </nav>

      <style jsx>{`
        button.navbar-burger {
          background: none;
          border: none;
        }

        nav {
          padding: 0 8px;
        }
      `}</style>
    </>
  );
};

export default BaseNav;
