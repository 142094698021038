import React from 'react';

import Link from 'components/Link';
import SocialLinks, { DownloadAndroidLink, DownloadIosLink } from 'components/social/SocialLinks';
import Logo from 'components/Logo';
import { HOMEPAGE_GUIDES } from 'lib/constants/pages';

const Footer = () => (
  <>
    <footer className="footer">
      <div className="container">
        <div className="columns">
          <div className="column is-2">
            <Link href="/">
              <strong>Vanly</strong>
            </Link>
            <br />
          </div>
          <div className="column">
            <p>
              <strong>Popular locations</strong>
            </p>
            {HOMEPAGE_GUIDES.map((g, i) => (
              <p key={i}>
                <Link route="stays.show" params={{ slug: g.slug }}>
                  {g.name}
                </Link>
              </p>
            ))}
          </div>
          <div className="column is-2">
            <p>
              <Link href="/about">About</Link>
            </p>
            <p>
              <Link href="/help">Help</Link>
            </p>
            <p>
              <Link href="/host">Host</Link>
            </p>
            <p>
              <Link href="https://trymasterkey.com?utm_source=vanly&utm_medium=footer&utm_campaign=referral">
                Masterkey
              </Link>
            </p>
            <p>
              <Link href="/blog">Blog</Link>
            </p>
            <p>
              <Link href="/terms">Terms</Link>
            </p>
            <p>
              <Link href="/privacy">Privacy</Link>
            </p>
          </div>
          <div className="column is-4 flex flex-col">
            <p className="flex-1" style={{ marginBottom: 32 }}>
              <a href="mailto:info@vanly.app">info@vanly.app</a>
            </p>
            <div className="flex">
              <DownloadIosLink className="flex-1" style={{ maxWidth: 145, marginRight: 8 }} />
              <DownloadAndroidLink className="flex-1" style={{ maxWidth: 145 }} />
            </div>
          </div>
        </div>
        <hr />
        <div className="hstack items-center">
          <div className="flex items-center flex-1">
            <Logo
              size={42}
              style={{ display: 'inline-block', verticalAlign: 'middle', marginRight: 16 }}
            />
            <small>© 2024 Vanly, Co. All rights reserved.</small>
          </div>
          <SocialLinks className="has-text-centered" />
        </div>
      </div>
    </footer>

    <style jsx>{`
      @import 'styles/variables';

      footer {
        line-height: 180%;
        margin-top: 4rem;
        color: $grey-dark;

        :global(a) {
          color: inherit;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    `}</style>
  </>
);

export default Footer;
