import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { useKeyPressEvent } from 'react-use';
import FocusLock from 'react-focus-lock';
import clsx from 'clsx';

import { useSequentialId } from 'lib/utils/sequentialId';
import useTransition from 'lib/hooks/useTransition';
import { parseForm } from 'lib/utils/form';
import { useIsMobile } from 'components/Responsive';
import { useLockScroll } from 'lib/hooks/useLockScroll';
import { useHijackBrowserBack } from 'lib/hooks/router';

const DEFAULT_LABELS = {
  cancel: 'Cancel',
  confirm: 'Confirm',
};

const Modal: React.FC<{
  active?: boolean;
  onConfirm?: (data: any) => any;
  onClose?: () => any;
  header?: React.ReactNode;
  confirmClass?: string;
  footer?: React.ReactNode;
  isForm?: boolean;
  confirmDisabled?: boolean;
  disableOverlayClose?: boolean;
  largeWidth?: boolean;
  fullScreen?: boolean;
  loading?: boolean;
  labels?: { cancel: React.ReactNode; confirm: React.ReactNode };
  modalClassName?: string;
  children?: React.ReactNode;
}> = ({
  active = false,
  onConfirm,
  onClose,
  header,
  confirmClass = null,
  footer = undefined,
  isForm = false,
  confirmDisabled = false,
  disableOverlayClose = false,
  largeWidth = false,
  fullScreen: forceFullScreen = null,
  loading = false,
  labels = DEFAULT_LABELS,
  modalClassName = null,
  children,
}) => {
  const sid = useSequentialId();
  const titleId = header ? `dialog-title-${sid}` : undefined;

  const Card = isForm ? 'form' : 'div';

  const [submittingForm, setSubmittingForm] = useState(false);

  const isMobile = useIsMobile();
  const fullScreen = forceFullScreen ?? isMobile; // if `forceFullScreen` is null/undefined, show fullScreen by default only on mobile

  useKeyPressEvent('Escape', active && onClose ? onClose : undefined);

  useHijackBrowserBack(
    active && onClose
      ? () => {
          onClose();
          return false; // prevent default back button behavior
        }
      : null,
  );

  const state = useTransition(active, 300);

  const lockScrollRef = useLockScroll(
    !!(active && state),
  ) as unknown as React.MutableRefObject<HTMLDivElement | null>;

  return (
    <>
      {!state
        ? null
        : createPortal(
            <FocusLock
              returnFocus
              as="div"
              className={clsx('modal is-active', fullScreen && 'modal--fullscreen', modalClassName)}
            >
              {!fullScreen && (
                <div
                  className={clsx(
                    'modal-background',
                    (state === 'in' || state === 'active') && 'fade-in',
                  )}
                  aria-hidden
                  onClick={!disableOverlayClose && onClose ? onClose : undefined}
                />
              )}

              <Card
                className={clsx(
                  'modal-card',
                  (state === 'in' || state === 'active') && 'fade-in',
                  largeWidth && 'is-fullwidth',
                )}
                role="dialog"
                aria-labelledby={titleId}
                style={{ opacity: 0 }}
                {...(isForm && onConfirm
                  ? {
                      // eslint-disable-next-line @typescript-eslint/no-misused-promises
                      onSubmit: async (e) => {
                        e.preventDefault();

                        if (submittingForm) return;

                        setSubmittingForm(true);
                        try {
                          await onConfirm(parseForm(e.target as HTMLFormElement));
                        } catch {}
                        setSubmittingForm(false);
                      },
                    }
                  : {})}
              >
                {header || (onClose && fullScreen) ? (
                  <header
                    className={clsx('modal-card-head', !header && 'has-background-white')}
                    id={titleId}
                  >
                    <div className="modal-card-head-content">
                      <div className="modal-card-title is-size-6">{header}</div>

                      {onClose && fullScreen ? (
                        <button
                          type="button"
                          className="delete"
                          aria-label="Close dialog"
                          onClick={onClose}
                        />
                      ) : null}
                    </div>
                  </header>
                ) : null}
                <div className="modal-card-body" ref={lockScrollRef}>
                  {children}
                </div>
                {footer !== null ? (
                  <footer className="modal-card-foot">
                    {footer || (
                      <div className="flex justify-between">
                        {onClose && labels.cancel ? (
                          <button
                            type="button"
                            className="button"
                            onClick={onClose}
                            disabled={submittingForm}
                          >
                            {labels.cancel}
                          </button>
                        ) : null}
                        {onConfirm && labels.confirm ? (
                          <button
                            type={isForm ? 'submit' : 'button'}
                            className={clsx(
                              'button',
                              confirmClass || 'is-primary',
                              loading && 'is-loading',
                            )}
                            disabled={loading || confirmDisabled || submittingForm}
                            onClick={isForm ? undefined : onConfirm}
                          >
                            {labels.confirm}
                          </button>
                        ) : null}
                      </div>
                    )}
                  </footer>
                ) : null}
              </Card>

              {onClose && !fullScreen ? (
                <button
                  type="button"
                  className="modal-close is-large"
                  aria-label="Close dialog"
                  onClick={onClose}
                />
              ) : null}
            </FocusLock>,
            document.body,
          )}

      <style jsx global>{`
        $transition: 300ms ease-in-out;

        .modal {
          padding: 20px;

          &--fullscreen {
            padding: 0;

            .modal-card {
              flex: 1;
              width: 100%;
              flex-basis: 100%;
              max-width: none !important;
              max-height: none;
              margin: 0;

              & > * {
                border-radius: 0;
              }
            }
          }
        }

        .modal-background {
          transition: opacity $transition;

          opacity: 0;
          &.fade-in {
            opacity: 1;
          }
        }

        .modal-card {
          transition: opacity $transition, transform $transition;

          min-width: 260px;

          &.is-fullwidth {
            width: 100%;
          }

          opacity: 0;
          transform: translateY(30px);
          &.fade-in {
            opacity: 1 !important;
            transform: none;
          }
        }
      `}</style>

      <style jsx>{`
        .modal-card-head {
          display: block;
          padding: 0;
        }
        .modal-card-foot {
          display: block;
          padding: 16px 20px;
        }

        .modal-card-head-content {
          padding: 16px 20px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-shrink: 0;
          position: relative;
        }

        .modal-card > * {
          $r: 12px;
          &:first-child {
            border-top-right-radius: $r;
            border-top-left-radius: $r;
          }
          &:last-child {
            border-bottom-right-radius: $r;
            border-bottom-left-radius: $r;
          }
        }

        @media (min-width: 768px) {
          .modal-card {
            max-width: 720px;
          }
        }
      `}</style>
    </>
  );
};

export default Modal;
