/// <reference types="googlemaps"/>

import * as _ from 'lodash-es';

import { loadScript } from 'lib/utils/loadScript';
import { isSubset } from 'lib/utils';
import { encodeQuery } from 'lib/utils/url';
import { request } from 'lib/request';
import { matchRoute } from 'lib/router';
import { IS_SSR, IS_PROD_ENV, FIREBASE_CONFIG } from 'lib/env';

// DO NOT EXPOSE THIS TO THE FRONTEND!
const GOOGLE_SERVER_API_KEY = process.env.GOOGLE_SERVER_API_KEY;

const GOOGLE_BROWSER_API_KEY = FIREBASE_CONFIG.apiKey;
// need a separate api key b/c timezone api doesn't allow api referer restrictions
const TIMEZONE_BROWSER_API_KEY = IS_PROD_ENV
  ? 'AIzaSyAeUMJZNDQEeSl0CD_NqckBR2XzPqBnzo4'
  : 'AIzaSyBz36AFvdqC8SczMdLlq90VnJsTcfSMjQI';

export type GMaps = typeof window.google.maps;

export const getGMaps = (): GMaps | null =>
  (typeof window !== 'undefined' && window.google?.maps) || null;

const DECODE_ADDRESS_COMPONENTS = {
  street_number: { comp: 'street_number', long: true },
  street: { comp: 'route', long: true },
  city: { comp: 'locality', long: true },
  state: { comp: 'administrative_area_level_1', long: false },
  county: { comp: 'administrative_area_level_2', long: true },
  country: { comp: 'country', long: false },
  postal_code: { comp: 'postal_code', long: true },
};

type DecodedComponents = Record<keyof typeof DECODE_ADDRESS_COMPONENTS, string | null> & {
  street_with_number: string | null;
};

const decodeComponents = (components: any[]): DecodedComponents => {
  const typeMap: any = {};
  for (const c of components) for (const t of c.types) typeMap[t] = c;

  const data: any = _.mapValues(DECODE_ADDRESS_COMPONENTS, ({ comp, long }) => {
    const names = typeMap[comp];
    return (names && (long ? names.long_name : names.short_name)) || null;
  });

  data.street_with_number = [data.street_number, data.street].filter(Boolean).join(' ') || null;

  return data;
};

type Point = { lat: number; lng: number };

type GeocodeInput =
  | { geocoords: Point; address?: never; placeId?: never }
  | { geocoords?: never; address: string; placeId?: never }
  | { geocoords?: never; address?: never; placeId: string };

export type GeocodeResult = DecodedComponents & {
  geocoords: Point;
  northEast: Point;
  southWest: Point;
  placeId: string;
  address: string;
};

export class GeocodeNoResultsError extends Error {
  constructor() {
    super('Geocoding found no results');
  }
}

const gmapsGeocode = async ({
  address,
  placeId,
  geocoords,
}: GeocodeInput): Promise<GeocodeResult> => {
  const gMaps = getGMaps()!;

  const [results, status] = await new Promise<
    [results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus]
  >((resolve) =>
    new gMaps.Geocoder().geocode(
      placeId ? { placeId } : geocoords ? { location: geocoords } : { address },
      (...args) => resolve(args),
    ),
  );

  const result = results?.[0];
  if (status === 'OK' && result) {
    // nothing
  } else if (status === 'OK' || status === 'ZERO_RESULTS') throw new GeocodeNoResultsError();
  else throw new Error(`Geocoding failed with status ${status}`);

  const viewport = result.geometry.viewport;
  const northEast = viewport.getNorthEast();
  const southWest = viewport.getSouthWest();

  return {
    geocoords: geocoords
      ? { ...geocoords }
      : {
          lat: result.geometry.location.lat(),
          lng: result.geometry.location.lng(),
        },
    northEast: {
      lat: northEast.lat(),
      lng: northEast.lng(),
    },
    southWest: {
      lat: southWest.lat(),
      lng: southWest.lng(),
    },
    placeId: result.place_id,

    address: result.formatted_address,
    ...decodeComponents(result.address_components),
  };
};

const apiGeocode = async ({
  address,
  placeId,
  geocoords,
}: GeocodeInput): Promise<GeocodeResult> => {
  const { status, results } = await request<any>({
    url: `https://maps.googleapis.com/maps/api/geocode/json`,
    query: {
      ...(placeId
        ? { place_id: placeId }
        : geocoords
        ? { latlng: `${geocoords.lat},${geocoords.lng}` }
        : { address }),
      key: GOOGLE_SERVER_API_KEY,
    },
  });

  const result = results?.[0];
  if (status === 'OK' && result) {
    // nothing
  } else if (status === 'OK' || status === 'ZERO_RESULTS') throw new GeocodeNoResultsError();
  else throw new Error(`Geocoding failed with status ${status}`);

  return {
    geocoords: {
      lat: result.geometry.location.lat,
      lng: result.geometry.location.lng,
    },
    northEast: result.geometry.viewport.northeast,
    southWest: result.geometry.viewport.southwest,
    placeId: result.place_id,

    address: result.formatted_address,
    ...decodeComponents(result.address_components),
  };
};

export const parseUrlSearchLocation = (value: string | undefined | null): string | null => {
  if (!value) return null;
  return value.replace(/--/g, ', ').replace(/-/g, ' ').trim() || null;
};

export const decodeListingsSearchLink = (
  url: string,
): null | (Record<string, any> & { parsedLocation: string | null }) => {
  const { route, params = {} } = matchRoute(url);

  if (route?.name !== 'search') return null;

  return {
    ...params,
    parsedLocation: parseUrlSearchLocation(params.location),
  };
};

export const encodeListingsSearchLink = ({
  lat,
  lng,
  city,
  state,
  country,
  address,
  zoom,
  radius,
  dates,
}: {
  lat?: number;
  lng?: number;
  city?: string;
  state?: string;
  country?: string;
  address?: string;
  zoom?: number;
  radius?: number;
  dates?: {
    start: Date;
    end: Date;
  };
}) => {
  if (lat == null || lng == null) {
    lat = lng = undefined;
  }

  const urlLocation = address
    ? address.replace(/[^a-zA-Z0-9]{2,}/g, '--').replace(/[^a-zA-Z0-9]/g, '-')
    : [city, state, country]
        .filter(Boolean)
        .map((d) => d!.replace(/,\s*/g, '--').replace(/\s+/g, '-'))
        .join('--');

  return `/search${urlLocation ? `/${urlLocation}` : ''}${encodeQuery(
    {
      lat,
      lng,
      zoom,
      radius,
      ...(dates && {
        start_date: dates.start.getTime(),
        end_date: dates.end.getTime(),
      }),
    },
    true,
  )}`;
};

export const encodePostalAddress = (d: {
  street_number?: string;
  street?: string; // can include street number, just don't pass `street_number` also!
  state?: string;
  city?: string;
  country?: string;
  postal_code?: string;
  zipcode?: string;
}) => {
  const ds = _.mapValues(d, (v) => v?.trim() || null);
  const street_with_number = [ds.street_number, ds.street].filter(Boolean).join(' ');
  const street_city_state = [street_with_number, ds.city, ds.state].filter(Boolean).join(', ');
  const street_city_state_postal = [street_city_state, ds.postal_code || ds.zipcode]
    .filter(Boolean)
    .join(' ');
  return [street_city_state_postal, ds.country || 'USA'].filter(Boolean).join(', ');
};

/**
 * Basic parsing of street name and number
 */
export const decodePostalAddress = (address: string) => {
  const parts = address
    .trim()
    .split(/\s*,\s*/)
    .map((p) => p.trim())
    .filter(Boolean);

  const street_with_number = parts[0];

  const [street_number, street] = street_with_number.split(/\s+/);

  return { street_with_number, street, street_number };
};

/** Provide EITHER `address`, `placeId`, or `geocoords` */
export const geocode = IS_SSR ? apiGeocode : gmapsGeocode;

export const getTimeZone = async ({ lat, lng }: Point, timestamp = Date.now()) => {
  const json = await request<any>({
    url: `https://maps.googleapis.com/maps/api/timezone/json`,
    query: {
      location: `${lat},${lng}`,
      timestamp: Math.floor(timestamp / 1000),
      key: IS_SSR ? GOOGLE_SERVER_API_KEY : TIMEZONE_BROWSER_API_KEY,
    },
  });

  const { status, timeZoneId, rawOffset, timeZoneName, dstOffset } = json;

  if (status === 'OK' && timeZoneId && rawOffset != null) {
    return {
      timeZoneId,
      timeZoneName, // human-readable name
      rawOffset: Math.floor(rawOffset / 60), // UTC offset in minutes
      // dstOffset: Math.floor(json.dstOffset / 60),
      offset: Math.floor((rawOffset + dstOffset) / 60), // UTC offset in minutes, and respects daylight savings
    };
  } else {
    const err = new Error('Failed to fetch timezone');
    // @ts-expect-error assignming Error value
    err.json = json;
    throw err;
  }
};

let gMapLibs: string[] | undefined;

export const DEFAULT_GMAP_LIBS = ['places'];

// TODO: it's impossible to dynamically load gmaps libraries after initial load:
// https://github.com/googlemaps/js-api-loader/issues/5,
// so we reload the page if we need to load different the libraries
export const requireGmapsLibraries = (libraries = DEFAULT_GMAP_LIBS) => {
  if (IS_SSR) throw new Error('Attempted to require gmaps libaries on the server');

  if (gMapLibs) {
    if (!isSubset(gMapLibs, libraries)) window.location.reload();
  } else {
    gMapLibs = libraries;
  }
};

export const loadGMaps = async (): Promise<GMaps> => {
  if (IS_SSR) throw new Error('Attempted to load gmaps JS on the server');

  await loadScript(
    `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_BROWSER_API_KEY}&libraries=${(
      gMapLibs || DEFAULT_GMAP_LIBS
    ).join(',')}`,
  );

  return getGMaps()!;
};

export const googleMapsGeopointLink = ({ lat, lng }: Point): string => {
  return `https://google.com/maps/search/?query=${lat},${lng}`;
};

const GMAP_IMAGE_SIZES = {
  original: { width: 900, height: 600 },
  default: { width: 900, height: 600 },
  thumb: { width: 200, height: 200 },
};

export const getListingStreetViewImage = (
  geocoords: Point,
  size: keyof typeof GMAP_IMAGE_SIZES = 'default',
) => {
  const { width, height } = GMAP_IMAGE_SIZES[size] || GMAP_IMAGE_SIZES.default;

  const query = {
    key: GOOGLE_BROWSER_API_KEY,
    location: `${_.round(geocoords.lat, 7)},${_.round(geocoords.lng, 7)}`,
    size: `${width}x${height}`,
    scale: 3,
    return_error_code: 'true',
  };
  return {
    src: `https://maps.googleapis.com/maps/api/streetview?${encodeQuery(query)}`,
    width,
    height,
  };
};

export const getListingSatelliteImage = (
  geocoords: Point,
  size: keyof typeof GMAP_IMAGE_SIZES = 'default',
) => {
  const { width, height } = GMAP_IMAGE_SIZES[size] || GMAP_IMAGE_SIZES.default;

  const query = {
    key: GOOGLE_BROWSER_API_KEY,
    center: `${_.round(geocoords.lat, 7)},${_.round(geocoords.lng, 7)}`,
    zoom: 18,
    size: `${width}x${height}`,
    maptype: 'satellite',
    scale: 3,
  };
  return {
    src: `https://maps.googleapis.com/maps/api/staticmap?${encodeQuery(query)}`,
    width,
    height,
  };
};

/**
 * Given a listing coordinates builds the link
 * for Google Maps
 */
export const buildGMapsLink = (point: Point, address?: string): string => {
  const query = {
    api: '1',
    destination: address || `${point.lat},${point.lng}`,
    travel_mode: 'driving',
  };
  return `https://www.google.com/maps/dir/?${encodeQuery(query)}`;
};
