import React, { useRef } from 'react';
import NextImageBase, { ImageLoader, ImageProps } from 'next/image';
import clsx from 'clsx';
import { useUpdate } from 'react-use';

import { MOBILE_APP, IS_DEV_ENV, HOST } from 'lib/env';
import { encodeQuery, relativeUrl } from 'lib/utils/url';
import { useLatestCallback } from 'lib/hooks/useLatestCallback';

export const imgLoader: ImageLoader = ({ src, width, quality }) => {
  if (!src) return '';

  // enforce relative path if same-origin
  src = relativeUrl(src, true) || src;

  return `${MOBILE_APP ? HOST : ''}/_next/image${encodeQuery(
    { url: src, w: width, q: quality || 75 },
    true,
  )}`;
};

const isObject = (val: unknown): val is Record<string, any> =>
  val != null && typeof val === 'object';

const hasBlurUrl = (props: ImageProps) => {
  if (isObject(props.src)) {
    if ('default' in props.src) {
      if (props.src.default?.blurDataURL) return true;
    } else if (props.src?.blurDataURL) return true;
  } else if (props.blurDataURL) {
    return true;
  }
  return false;
};

// You must use this instead of importing `next/image`
export const NextImage: React.FC<ImageProps> = (props) => {
  return (
    <NextImageBase
      loader={imgLoader}
      placeholder={hasBlurUrl(props) ? 'blur' : 'empty'}
      {...props}
    />
  );
};

const transparentSrc: StaticImageData = {
  src: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAEklEQVQImWO4ffv2f2TMQLoAAI9NOQGyh0+JAAAAAElFTkSuQmCC',
  width: 4,
  height: 4,
};

type StaticImageData = {
  src: string;
  width?: number;
  height?: number;
  blurDataURL?: string;
};

type StaticImageDataWithFallback = StaticImageData & {
  fallbackSrc?: string | StaticImageData;
};

type SrcWithFallback =
  | string
  | StaticImageDataWithFallback
  | { default: StaticImageDataWithFallback };

type LazyImageProps = Omit<ImageProps, 'src' | 'alt' | 'layout' | 'objectFit'> & {
  src: SrcWithFallback | undefined;
  alt?: string;
  cover?: boolean;
  fallbackSrc?: string | StaticImageData;
  width?: number;
  objectFit?: React.CSSProperties['objectFit'];
  layout?: never;
};

const LazyImage: React.FC<LazyImageProps> = ({
  children,
  style,
  className,
  cover = false,
  fallbackSrc,

  // passed to NextImage
  src,
  blurDataURL,
  width,
  height,
  alt = '',
  objectFit = 'cover',
  unoptimized,
  priority,
  fill,
  ...rest
}) => {
  if (children) cover = true;

  let srcString: string | undefined;
  if (isObject(src)) {
    if ('default' in src) src = src.default;
    srcString = src.src;
    fallbackSrc ||= src.fallbackSrc;
  } else {
    srcString = src;
  }

  if (!srcString && IS_DEV_ENV) {
    console.warn(`Missing src in LazyImage`);
  }

  const srcOrder: (SrcWithFallback | undefined)[] = [src, fallbackSrc, transparentSrc];

  const update = useUpdate();
  const srcIndex = useRef<number | undefined | null>(null);
  const lastSrc = useRef<string | undefined | null>(null);
  if (lastSrc.current !== srcString || srcIndex.current == null) {
    lastSrc.current = srcString;
    srcIndex.current = srcOrder.findIndex(Boolean);
  }

  const isOriginalSrc = srcIndex.current === 0;

  // only use `props.blurDataUrl` if original src
  if (!isOriginalSrc) blurDataURL = undefined;

  let currentSrc = srcOrder[srcIndex.current ?? 0];
  if (isObject(currentSrc)) {
    if ('default' in currentSrc) currentSrc = currentSrc.default;
    width ??= currentSrc.width;
    height ??= currentSrc.height;
    blurDataURL ||= currentSrc.blurDataURL;
    currentSrc = currentSrc.src;
  }

  // assert that currentSrc is not an object anymore
  if (isObject(currentSrc)) {
    throw new Error(`Invalid src: ${JSON.stringify(currentSrc)}`);
  }

  // don't optimize SVGs nor gmaps street-view nor satellite-view images
  if (
    unoptimized == null &&
    (currentSrc?.endsWith('.svg') ||
      currentSrc?.startsWith('https://maps.googleapis.com/maps/api/'))
  )
    unoptimized = true;

  // custom handler for Contentful images
  if (isOriginalSrc && currentSrc?.startsWith('https://images.ctfassets.net/')) {
    if (!currentSrc.includes('?'))
      currentSrc = `${currentSrc}${encodeQuery(
        { fm: 'jpg', fl: 'progressive', h: Math.min(1200, width ?? 1200) },
        true,
      )}`;

    // don't optimize them by default
    unoptimized ??= true;
  }

  // safely fallback to `fill=true` if missing `width` or `height` (otherwise Next.js throws an error)
  if (!fill && (!width || !height)) {
    fill = true;
    width = height = undefined;
  }

  return (
    <div
      className={clsx(
        'LazyImage',
        cover && 'LazyImage--cover',
        // objectFit !== 'none' && objectFit != null && 'LazyImage--fit',
        className,
      )}
      style={style}
    >
      <NextImage
        src={currentSrc!} // let next.js handle undefined `src`
        // TODO: blur does not appear to be working:
        blurDataURL={blurDataURL}
        width={width}
        height={height}
        alt={alt ?? ''}
        fill={fill}
        style={{
          objectFit,
        }}
        priority={priority}
        unoptimized={unoptimized}
        onError={useLatestCallback(() => {
          console.error(`LazyImage load error: ${currentSrc})`);
          // increment srcIndex to the next available src
          if (srcIndex.current != null && srcIndex.current < srcOrder.length - 1) {
            const prev = srcIndex.current;
            srcIndex.current = srcOrder.findIndex((s, i) => i > srcIndex.current! && s);
            if (prev !== srcIndex.current) update();
          }
        })}
        {...rest}
      />
      {children}

      <style jsx>{`
        @import 'styles/variables';

        .LazyImage {
          overflow: hidden;
          position: relative;

          > :global(img[data-nimg]) {
            // alt text flashes on error before fallbackSrc can render, so set alt text color to transparent
            // TODO: this is probably bad for SEO
            color: transparent;

            // css transition for placeholder="blur"
            transition: filter 100ms linear;

            // fill the container:
            width: 100%;
            height: 100%;
            display: block;
          }

          &--cover > :global(img[data-nimg]) {
            position: absolute !important;
            left: 0;
            top: 0;
            width: 100%;
          }
        }
      `}</style>
    </div>
  );
};

export default LazyImage;
