const Logo = ({ style = {}, size, className, ...rest }) => (
  <svg
    viewBox="0 0 63.892 34.145"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    title="Vanly Logo"
    className={className}
    style={{ width: size, ...style }}
    {...rest}
  >
    <path
      fill="currentColor"
      d="M63.841 14.192a13.62 13.62 0 00-.178-2.11h-9.838a.87.87 0 01-.877-.87V6.378c0-.477.392-.87.877-.87h7.015a.703.703 0 00-.093-.114A14.362 14.362 0 0051.223.005h-3.6c-.514-.007-.785.014-1.32.014h-.063L46.11.005H17.78a61.83 61.83 0 00-1.612 0h-3.6a14.378 14.378 0 00-9.523 5.39 5.223 5.223 0 01-.094.114h7.065c.478 0 .87.392.87.87v4.833c0 .485-.392.87-.87.87H.128c-.056.356-.1.727-.128 1.105V27.41a2.112 2.112 0 001.476 1.326h7.5v-.021a8.83 8.83 0 018.833-8.833 8.83 8.83 0 018.833 8.833v.021h10.665v-.093a8.83 8.83 0 018.833-8.833 8.83 8.83 0 018.833 8.833v.093h7.385a2.106 2.106 0 001.533-1.526V14.192zm-35.524-2.98c0 .485-.392.87-.87.87h-9.11a.864.864 0 01-.87-.87V6.38c0-.478.385-.87.87-.87h9.11c.478 0 .87.392.87.87zm17.78 0c0 .485-.392.87-.87.87h-9.11a.87.87 0 01-.878-.87V6.38c0-.478.393-.87.877-.87h9.112c.477 0 .869.392.869.87z"
    />

    <path
      fill="currentColor"
      d="M51.581 28.527a5.457 5.457 0 11-10.914-.002 5.457 5.457 0 0110.914.002zM23.179 28.69a5.455 5.455 0 11-10.91 0 5.456 5.456 0 0110.91 0z"
    />
  </svg>
);

export const TextLogo = ({ style = {}, size, className, ...rest }) => (
  <svg
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 107.71 36.074"
    className={className}
    style={{ width: size, ...style }}
    {...rest}
  >
    <path
      fill="currentColor"
      d="M19.056 1.362L12.775 20.39 6.53 1.362H0l8.933 25.194h7.684L25.55 1.362zM41.883 6.53v2.838c-.577-.934-1.39-1.69-2.46-2.26-1.062-.578-2.302-.863-3.714-.863-1.653 0-3.144.421-4.484 1.255-1.34.84-2.403 2.032-3.18 3.593-.777 1.554-1.162 3.358-1.162 5.418 0 2.053.385 3.87 1.162 5.432.777 1.57 1.84 2.78 3.18 3.629 1.34.849 2.823 1.276 4.449 1.276 1.411 0 2.651-.292 3.714-.863 1.07-.577 1.896-1.326 2.495-2.26v2.83h6.138V6.53h-6.138zm-1.276 13.638c-.848.884-1.882 1.333-3.101 1.333s-2.26-.449-3.109-1.347c-.848-.898-1.269-2.11-1.269-3.643s.42-2.738 1.27-3.608c.848-.877 1.89-1.311 3.108-1.311 1.219 0 2.253.441 3.1 1.326.856.884 1.277 2.096 1.277 3.628 0 1.533-.42 2.738-1.276 3.622zM70.289 8.598c-1.397-1.518-3.272-2.281-5.618-2.281-1.318 0-2.502.257-3.55.77-1.055.513-1.896 1.219-2.516 2.103V6.53h-6.139v20.026h6.139V15.684c0-1.34.35-2.38 1.04-3.122.692-.742 1.626-1.112 2.802-1.112 1.17 0 2.103.37 2.795 1.112.699.741 1.048 1.782 1.048 3.122v10.872h6.095V14.857c0-2.652-.699-4.74-2.096-6.259zM76.659 0v26.556h6.138V0zM101.069 6.53l-4.592 12.526L91.844 6.53h-6.859l8.149 19.349-4.591 10.195h6.6l12.563-29.543z"
    />
  </svg>
);

export default Logo;
