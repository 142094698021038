import * as _ from 'lodash-es';

import { AMENITIES } from 'lib/constants/listings';

type QueryType = 'float' | 'int' | 'boolean' | 'string' | 'date';
type MapData = QueryType | { type: QueryType; path?: string };

export type DecodeQueryMap = Record<string, MapData>;

export const QUERY_MAP: DecodeQueryMap = {
  page: 'int',
  lat: 'float',
  lng: 'float',
  south: 'float',
  west: 'float',
  north: 'float',
  east: 'float',
  start_date: 'date',
  end_date: 'date',
  radius: 'float',
  zoom: 'float',
  price: 'float',
  size: 'string',
  instant_book: 'boolean',
  ..._.fromPairs(AMENITIES.map((a) => [a, { type: 'boolean', path: 'amenities' }])),
};

export const CONSTRAIN_ZOOM = {
  min: 5, // zoomed out
  max: 17, // zoomed in
};
