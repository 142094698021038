import * as _ from 'lodash-es';
import React, { useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { useCombobox } from 'downshift';

import { toasts } from 'components/alert';
import { geocode } from 'lib/geo';
import { trackError } from 'lib/tracking';
import { useLazyGMaps } from 'lib/hooks/geo';

const ICON_CLASSES = {
  search: 'fa-search',
  home: 'fa-home',
};

const shortenAddress = (address) => {
  address = address?.trim();
  if (!address) return '';
  return address.match(/^[^,]+/)?.[0]?.trim() || address || '';
};

const itemToString = (item) => item.description;

const LocationSearchInput = ({
  initValue = '',
  icon: iconType = null,
  iconColorClass = 'has-text-dark',
  className,
  inputClassName,
  controlClassName,
  placeholder = 'e.g. 123 Main St.',
  // a comma-separated string of google-places-autocomplete `types`
  searchType = 'address',
  showStreetOnly = false,
  useShortDisplay = false,
  showClearButton = false,
  onChange = null,
  onSelect,
  allowNotFoundResult = false,
}) => {
  const [gMaps, startLoadGmaps] = useLazyGMaps(true);

  /** @type {[google.maps.places.AutocompletePrediction[]]} */
  const [items, setItems] = useState([]);

  const [autocompleting, setAutocompleting] = useState(false);
  const [geocoding, setGeocoding] = useState(false);
  const loading = autocompleting || geocoding;

  const inputRef = useRef();

  const placesAutocomplete = useMemo(
    () => (gMaps?.places ? new gMaps.places.AutocompleteService() : null),
    [gMaps],
  );

  const loadPredictions = useMemo(
    () =>
      !placesAutocomplete
        ? _.noop
        : _.debounce(async (input) => {
            if (!input) return;

            const { OK, ZERO_RESULTS } = gMaps.places.PlacesServiceStatus;

            try {
              /** @type {google.maps.places.AutocompletePrediction[]} */
              const predictions = await new Promise((resolve, reject) =>
                placesAutocomplete.getPlacePredictions(
                  {
                    input,
                    types: searchType.split(','),
                    componentRestrictions: { country: 'us' },
                  },
                  (preds, status) =>
                    [OK, ZERO_RESULTS].includes(status)
                      ? resolve(preds || [])
                      : reject(new Error(`Places autocomplete error: ${status}`)),
                ),
              );

              // if input value changed, then cancel
              if (inputRef.current?.value !== input) return;

              if (allowNotFoundResult && !predictions.length) {
                setItems([
                  {
                    place_id: null,
                    description: input,
                    __notFoundResult: true,
                  },
                ]);
              } else {
                setItems(predictions);
              }
            } catch (err) {
              trackError(err, 'LocationSearchInput getPlacePredictions error');
              toasts.error('Please try another search');
              setItems([]);
            }

            setAutocompleting(false);
          }, 300),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [placesAutocomplete],
  );

  let setInputValue;

  const handleSelect = async (placeId, userSearchTerm) => {
    setGeocoding(true);
    try {
      const placeData = await geocode({ placeId });

      const addressString = showStreetOnly ? placeData.street_with_number : placeData.address;

      setInputValue?.(useShortDisplay ? shortenAddress(addressString) : addressString);

      inputRef.current?.blur();

      onSelect?.({
        ...placeData,
        searchTerm: addressString,
        originalSeachTerm: userSearchTerm,
      });
    } catch (err) {
      trackError(err, 'LocationSearchInput geocode error');
      toasts.error('Please try another search');
    }
    setGeocoding(false);
  };

  const {
    inputValue,
    isOpen,
    highlightedIndex,
    getInputProps,
    getItemProps,
    getMenuProps,
    getToggleButtonProps,
    setInputValue: setInputValue_,
  } = useCombobox({
    initialInputValue: useShortDisplay ? shortenAddress(initValue) : initValue || '',
    // on selectedItem change, don't change the value of the input
    // b/c we want to set it to `addressString` (see `handleSelect`)
    itemToString: () => inputRef.current?.value || '',
    items,
    defaultHighlightedIndex: 0,
    onInputValueChange: (v) => {
      if (v.inputValue?.length) {
        setAutocompleting(true);
        loadPredictions(v.inputValue);
      } else {
        setGeocoding(false);
        setAutocompleting(false);
        setItems([]);
      }

      onChange?.(v.inputValue || '');
    },
    onSelectedItemChange: (c) => {
      const pred = c.selectedItem;

      if (pred?.__notFoundResult) {
        onSelect?.({
          notFoundResult: true,
          searchTerm: c.inputValue,
        });
      } else if (pred?.place_id) {
        handleSelect(pred.place_id, c.inputValue);
      }
    },
  });

  setInputValue = setInputValue_;

  const iconClass = iconType && ICON_CLASSES[iconType];

  const dropdownWidth = `${inputRef.current?.clientWidth ?? 361}px`;

  return (
    <div className={clsx('dropdown', isOpen && 'is-active', className)}>
      <div
        className={clsx(
          'dropdown-trigger control has-icons-right',
          iconClass && 'has-icons-left',
          controlClassName,
        )}
        {...getToggleButtonProps()}
      >
        <input
          {...getInputProps({
            ref: inputRef,

            // if user hovers or focuses this input, immediately start loading google maps library
            onFocus: gMaps ? undefined : startLoadGmaps,
            onMouseEnter: gMaps ? undefined : startLoadGmaps,
          })}
          className={inputClassName}
          aria-label={placeholder}
          placeholder={placeholder}
        />
        {iconClass ? (
          <span className={clsx('icon is-small is-left', iconColorClass)} aria-hidden>
            <i className={clsx('fas', iconClass)} />
          </span>
        ) : null}
        {loading ? (
          <span className="icon is-right is-small has-text-grey-lighter" aria-hidden>
            <i className="fas fa-circle-notch fa-spin" />
          </span>
        ) : showClearButton && !!inputValue ? (
          <button
            type="button"
            onClick={() => {
              setInputValue?.('');
              inputRef.current?.focus();
            }}
            className="button-reset clear-search icon is-right is-small has-text-grey-lighter"
            aria-label="Clear search"
          >
            <i className="fas fa-times-circle" />
          </button>
        ) : null}
      </div>
      <div className="dropdown-menu" {...getMenuProps({ style: { outline: 'none' } })}>
        {isOpen && items?.length ? (
          <div className="dropdown-content">
            {items.map((item, idx) => (
              <a
                key={item.place_id ?? idx}
                className={clsx(
                  'dropdown-item has-text-weight-normal',
                  highlightedIndex === idx && 'is-active',
                )}
                {...getItemProps({
                  index: idx,
                  item,
                })}
              >
                {itemToString(item)}
              </a>
            ))}
          </div>
        ) : null}
      </div>

      <style jsx>{`
        @import 'styles/variables';

        input {
          resize: horizontal;
        }

        button {
          pointer-events: all !important;
        }

        .dropdown {
          display: block;
        }

        .dropdown-menu {
          width: ${dropdownWidth};
        }
        .dropdown-item {
          color: $dark !important;
          font-size: 16px;
          white-space: normal;
          padding: 0.5rem 0.75rem;

          &[aria-selected='true'] {
            background: $grey-lighter;
          }
        }

        .icon.is-right {
          top: 50%;
          transform: translateY(-50%);
          color: $grey-light;
        }
        .icon.is-right.clear-search {
          pointer-events: all;
          border-radius: 50%;
          &:hover {
            color: $grey !important ;
          }
        }

        .fa-spin {
          animation-duration: 1.5s;
        }
      `}</style>
    </div>
  );
};

export default React.memo(LocationSearchInput);
