import { differenceInCalendarDays } from 'date-fns';
import * as _ from 'lodash-es';

import type { FDay, ListingDoc, PrivateListingDoc } from 'schemas/types';
import { safeParseInt } from 'lib/utils';
import { dateToDay, dayToDate } from 'lib/utils/time';
import { encodePostalAddress } from 'lib/geo';

type AvailabilityData = { startDate?: Date | null; allowPastDates?: boolean } & Partial<
  Pick<
    ListingDoc,
    'bookingNoticeLimit' | 'unavailableDays' | 'nightsMax' | 'nightsMin' | 'howFarCanGuestsBook'
  >
>;

export const makeIsDateAvailable = (
  data: AvailabilityData,
  now = new Date(),
  syncedUnavailableDays: FDay[] = [],
) => {
  const { startDate, allowPastDates = false } = data;

  const bookingNoticeLimit = safeParseInt(data.bookingNoticeLimit, true);
  const howFarCanGuestsBook = safeParseInt(data.howFarCanGuestsBook, true);
  const nightsMin = safeParseInt(data.nightsMin, true) || 1;
  const nightsMax = safeParseInt(data.nightsMax, true);

  const unavailableDays =
    !_.isEmpty(data.unavailableDays) || !_.isEmpty(syncedUnavailableDays)
      ? new Set([
          ...Object.keys(data.unavailableDays || {}).map(Number),
          ...(Array.isArray(syncedUnavailableDays) ? syncedUnavailableDays : []),
        ])
      : null;

  const startDay = startDate ? dateToDay(startDate) : null;

  const minUnavailableDay =
    startDay && unavailableDays
      ? _.min([...unavailableDays].filter((day) => day > startDay))
      : null;

  let unavailableToStart: Set<number>;
  if (unavailableDays) {
    unavailableToStart = new Set();
    for (const day of unavailableDays) {
      for (let i = 1; i <= nightsMin; i++) unavailableToStart.add(day - i);
    }
  }

  return (date: Date): boolean => {
    const day = dateToDay(date);

    const diffNow = differenceInCalendarDays(date, now);

    // is in the past
    if (!allowPastDates && diffNow < 0) return false;

    // user marked as unavailable
    if (unavailableDays?.has(day)) return false;

    if (!startDate && unavailableToStart?.has(day)) return false;

    // too close
    if (bookingNoticeLimit && diffNow < bookingNoticeLimit) return false;

    // too far (only applicable when selecting a start date)
    if (!startDate && howFarCanGuestsBook && diffNow > howFarCanGuestsBook) return false;

    // intersects with an unavailable date
    if (minUnavailableDay != null && day >= minUnavailableDay) return false;

    const startDiff = startDate ? differenceInCalendarDays(date, startDate) : null;
    if (startDiff !== null) {
      if (startDiff === 0) return true;

      // too short
      if (startDiff < nightsMin) return false;

      // too long
      if (nightsMax && startDiff > nightsMax) return false;
    }

    return true;
  };
};

export const canBookListingDates = (listing: ListingDoc, from: FDay, to: FDay): boolean => {
  const isDateAvailable = makeIsDateAvailable(listing);

  for (let day = from; day <= to; day++) {
    if (!isDateAvailable(dayToDate(day))) return false;
  }

  return true;
};

// TODO: use this
export const fullListingLocation = (
  listing: ListingDoc,
  privateListing: PrivateListingDoc | null = null,
) => {
  return encodePostalAddress({
    street: privateListing?.street || listing.street,
    city: listing.city,
    state: listing.state,
    country: listing.country,
  });
};
