import React from 'react';
import clsx from 'clsx';
import { App as CapApp } from '@capacitor/app';

import Footer from 'components/Footer';
import MainNav from 'components/Navbars/MainNav';
import MobileNav from 'components/Navbars/MobileNav';
import BottomMobileNav from 'components/BottomMobileNav';
import PreviewModeBanner from 'components/Banners/PreviewModeBanner';
import { useIsMobile } from 'components/Responsive';

import { useHijackBrowserBack, useRouter } from 'lib/hooks/router';
import { MOBILE_APP, PLATFORM } from 'lib/env';
import Router from 'lib/router';

const Layout = ({
  banner = null,
  navOverlap = false,
  navbar: webNavbar,
  mobileNav: mobileNavbar,
  footer: webFooter,
  mobileFooter,
  mobileWebFooter = false,
  mobileWebNavbar = false,
  showBannerBelowNav = false,
  mobileNavEnd = null,
  children,
  /** The url we return to when pressing back button or swiping back. `false` indicates we cannot go back. */
  mobileReturnTo = null,
}) => {
  const isMobile = useIsMobile();
  const { isPreview } = useRouter();

  useHijackBrowserBack(
    MOBILE_APP
      ? () => {
          console.log(`hijack Layout: mobileReturnTo=${mobileReturnTo}`);
          if (mobileReturnTo === false) {
            if (PLATFORM === 'android') {
              CapApp.exitApp();
            }
            return false;
          } else if (typeof mobileReturnTo === 'string' && mobileReturnTo) {
            Router.replace(mobileReturnTo);
            return false;
          } else {
            return true;
          }
        }
      : null,
  );

  const showMobileNavbar = MOBILE_APP || (isMobile && mobileWebNavbar);
  const navbar = showMobileNavbar ? (
    mobileNavbar === undefined ? (
      <MobileNav
        navBarEnd={mobileNavEnd}
        returnTo={mobileReturnTo || null}
        noBackButton={mobileReturnTo === false}
      />
    ) : mobileNavbar === null ? null : (
      React.cloneElement(mobileNavbar, {
        navBarEnd: mobileNavEnd,
        returnTo: mobileReturnTo || null,
        noBackButton: mobileReturnTo === false,
      })
    )
  ) : webNavbar === undefined ? (
    <MainNav />
  ) : (
    webNavbar
  );
  const showMobileFooter = MOBILE_APP || (isMobile && mobileWebFooter);
  const footer = showMobileFooter ? (
    mobileFooter === undefined ? (
      <BottomMobileNav />
    ) : (
      mobileFooter
    )
  ) : webFooter === undefined ? (
    <Footer />
  ) : (
    webFooter
  );

  return (
    <div id="__root">
      <div className={clsx('sticky-top', navOverlap && 'sticky-top--nav-overlap')}>
        {showBannerBelowNav ? navbar : banner}
        {isPreview ? <PreviewModeBanner /> : null}
        {showBannerBelowNav ? banner : navbar}
      </div>
      <main>{children}</main>
      {footer}

      <style jsx>{`
        @import 'styles/variables';

        .sticky-top {
          display: flex;
          flex-direction: column;
          position: sticky;
          top: 0;
          left: 0;
          width: 100%;
          z-index: 30;

          &--nav-overlap {
            margin-bottom: -$navbar-height;
          }
        }

        main {
          padding-bottom: ${showMobileFooter && mobileFooter !== null ? '100px' : '0'};
        }
      `}</style>
    </div>
  );
};

export default Layout;
