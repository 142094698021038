import * as _ from 'lodash-es';

import type { DecodeQueryMap } from 'lib/constants/search';

/*
 * Random helper functions. Prefer using a separate file!
 */

export const parseNum = (val: any): number | null => {
  if (typeof val === 'string' && val !== '') val = Number(val);
  if (typeof val === 'number' && !Number.isNaN(val)) return val;

  return null;
};

export const parseInt = (val: any, convertFloats = false): number | null => {
  const num = parseNum(val);
  if (num == null) return null;

  if (Number.isInteger(num)) return num;

  if (convertFloats) return Math.floor(num);

  return null;
};

export const safeParseInt = (num: any, mustBePositive = false): number | null => {
  num = parseNum(num);
  if (num == null) return null;

  if (mustBePositive && num < 0) return null;

  return Math.floor(num);
};

export const isNumberInRange = (val: any, min: number, max: number): boolean => {
  val = parseNum(val);
  return val != null && val >= min && val <= max;
};

const toDate = (val: any): Date | null => {
  const int = parseInt(val);
  if (int) {
    const date = new Date(int);
    return Number.isNaN(date.getTime()) ? null : date;
  }
  return null;
};

export const mapQuery = (query: Record<string, any>, map: DecodeQueryMap) => {
  const res = {};
  for (const key in query) {
    let value = query[key] || '';
    let mapData = map[key];
    if (mapData) {
      if (typeof mapData === 'string') mapData = { type: mapData };
      switch (mapData.type) {
        case 'int':
          value = parseInt(value);
          break;
        case 'float':
          value = parseNum(value);
          break;
        case 'boolean':
          value =
            value === 'true' || value === '1'
              ? true
              : value === 'false' || value === '0'
              ? false
              : null;
          break;
        case 'date':
          value = toDate(value);
          break;
        // case 'string':
        default:
          value = value.length === 0 ? null : value;
      }
      if (value != null) {
        _.set(res, [mapData.path, key].filter(Boolean).join('.'), value);
      }
    }
  }
  return res;
};

export const toRad = (v: number): number => (v * Math.PI) / 180;

export const splitArray = <T>(arr: T[]): [T[], T[]] => [
  arr.slice(0, Math.floor(arr.length / 2)),
  arr.slice(Math.floor(arr.length / 2)),
];

interface CompactObj {
  <Obj extends Record<string, any>>(obj: Obj): { [key in keyof Obj]?: NonNullable<Obj[key]> };
  <Obj extends any[]>(obj: Obj): NonNullable<Obj[number]>[];
}
export const compactObj = ((obj: any) => {
  if (Array.isArray(obj)) {
    return obj.filter((v) => v != null);
  } else {
    return _.transform<string, Record<string, any>>(
      obj,
      (acc, val, key) => {
        if (val != null) acc[key] = val;
      },
      {},
    );
  }
}) as CompactObj;

export const compactObjTruthy = <Obj extends Record<string, any>>(obj: Obj): Obj => {
  return _.transform(
    obj,
    (acc, val, key) => {
      if (val) acc[key as keyof Obj] = val;
    },
    {} as Obj,
  );
};

/**
 * Is `subset` a subset of `superset`?
 */
export const isSubset = (
  subset: unknown[] | undefined | null,
  superset: unknown[] | undefined | null,
): boolean => {
  if (!subset?.length) return true;
  if (!superset?.length) return false;

  for (const el of subset) if (!superset.includes(el)) return false;

  return true;
};

/**
 * Given a `require.context(...)` or `import.meta.webpackContext(...)`, return a map of relative filenames (without file extension)
 * to their respective module export
 */
export const loadModuleMap = <R>(requireCtx: __WebpackModuleApi.RequireContext) => {
  const map: Record<string, R> = {};
  for (const key of requireCtx.keys()) {
    const skey = key.replace(/\.\w+$/, '').replace(/^\.\//, '');
    const val = requireCtx(key);
    map[skey] = typeof val === 'object' && val !== null && 'default' in val ? val.default : val;
  }
  return map;
};

export const safeJSONParseObj = <T extends Record<string, any>>(
  str: string | undefined | null,
): T | null => {
  if (!str || typeof str !== 'string') return null;
  try {
    const obj = JSON.parse(str);
    if (_.isPlainObject(obj)) return obj;
  } catch {}
  return null;
};
