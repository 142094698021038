import { IS_PROD_DATA } from 'lib/env';
import type { MetaListingDoc } from 'lib/search/syncListings';
import type * as T from 'schemas/types';

export type RootDocs = {
  users: T.UserDoc;
  listings: T.ListingDoc;
  private_listings: T.PrivateListingDoc;
  meta_listings: MetaListingDoc;
  bookings: T.BookingDoc;
  profiles: T.ProfileDoc;
  chats: T.ChatDoc;
  meta: T.MetaDataDoc;
  admins: T.AdminDoc;
  user_source_attributions: T.UserSourceAttributionDoc;
  product_feedbacks: T.ProductFeedbackDoc;
  stripe_customers: T.StripeCustomerDoc;
  stripe_accounts: T.StripeAccountDoc;
  email_trackers: T.EmailTrackerDoc;
  short_links: T.ShortLinkDoc;
  install_attributions: T.InstallAttributionDoc;
  coupons: T.CouponDoc;
  newsletters: T.NewsletterDoc;
  listings_feedbacks: T.ListingFeedbackDoc;
  saved_listings: T.SavedListingDoc;
  monthly_kpis: T.MonthlyKPIsDoc;
  support_inquiries: T.SupportInquiryDoc;
  photographer_profiles: T.PhotographerProfileDoc;
  synced_calendars: T.SyncedCalendarDoc;

  // sub-collections
  profile_reviews: T.ProfileReviewDoc;
  listing_reviews: T.ListingReviewDoc;
  vehicles: T.VehicleDoc;
  notifications: T.NotificationDoc;
  chat_messages: T.ChatMessageDoc;
  support_inquiry_messages: T.SupportInquiryMessageDoc;
  // chat_users: T.ChatUserDoc;
  meta_daily_history: T.MetaDailyDoc;
  holiday_profiles: T.HolidayProfileDoc;
};

export const ROOT_COLLECTIONS = [
  'users',
  'listings',
  'private_listings',
  'meta_listings',
  'bookings',
  'profiles',
  'chats',
  'meta',
  'admins',
  'user_source_attributions',
  'product_feedbacks',
  'stripe_customers',
  'stripe_accounts',
  'email_trackers',
  'short_links',
  'install_attributions',
  'synced_calendars',
  'coupons',
  'saved_listings',
  'newsletters',
  'listings_feedbacks',
  'monthly_kpis',
  'holiday_profiles',
  'support_inquiries',
  'photographer_profiles',
] as const;

/**
 * [root-collection, sub-collection, key-name on the C object]
 */
export const SUB_COLLECTIONS = [
  ['chats', 'chat_messages', 'chat_messages'],
  // ['chats', 'chat_users', 'chat_users'],
  ['users', 'notifications', 'notifications'],
  ['profiles', 'vehicles', 'vehicles'],
  ['profiles', 'reviews', 'profile_reviews'],
  ['listings', 'reviews', 'listing_reviews'],
  ['meta', 'daily_history', 'meta_daily_history'],
  ['support_inquiries', 'support_messages', 'support_inquiry_messages'],
] as const;

export const BUCKET_IDS = {
  listingImages: IS_PROD_DATA ? 'vanly-listings' : 'vanly-dev-listings',
  avatarImages: IS_PROD_DATA ? 'vanly-avatars' : 'vanly-dev-avatars',
  messageUploads: IS_PROD_DATA ? 'vanly-messageuploads' : 'vanly-dev-messageuploads',
  listingReviewFiles: IS_PROD_DATA ? 'vanly-listingreviews' : 'vanly-dev-listingreviews',
};
export const SERVER_BUCKET_IDS = {
  internal: IS_PROD_DATA ? 'vanly-internal' : 'vanly-internal-dev',
};

export const HUMAN_READABLE_SINGLE_COLLECTIONS = {
  users: 'User',
  listings: 'Listing',
  private_listings: 'Private Listing',
  meta_listings: 'Meta Listing',
  bookings: 'Booking',
  profiles: 'Profile',
  chats: 'Chat',
  meta: 'Meta Data',
  admins: 'Admin',
  user_source_attributions: 'User Source Attribution',
  product_feedbacks: 'Product Feedback',
  stripe_customers: 'Stripe Customer',
  stripe_accounts: 'Stripe Account',
  email_trackers: 'Email Tracker',
  short_links: 'Short Link',
  install_attributions: 'Install Attribution',
  synced_calendars: 'Synced Calendars',
  coupons: 'Coupon',
  saved_listings: 'Saved Listing',
  newsletters: 'Newsletter',
  listings_feedbacks: 'Listings Feedback',
  monthly_kpis: 'Monthly KPI',
  holiday_profiles: 'Holiday Profile',
  support_inquiries: 'Support Case',
};
