export const COLORS = {
  orange: '#C19100',
  purple: '#8D5A96',
  green: '#465F3A',
};
export const COLOR_LIST = Object.values(COLORS);

export const HOMEPAGE_GUIDES = [
  {
    slug: 'san-diego',
    name: 'San Diego',
    color: COLORS.purple,
  },
  {
    slug: 'adirondack-park',
    name: 'Adirondack Park',
    color: COLORS.green,
  },
  {
    slug: 'yellowstone',
    name: 'Yellowstone National Park',
    color: COLORS.orange,
  },
  {
    slug: 'sequoia',
    name: 'Sequoia National Park',
    color: COLORS.green,
  },
  {
    slug: 'crater-lake',
    name: 'Crater Lake',
    color: COLORS.purple,
  },
  {
    slug: 'sedona',
    name: 'Flagstaff & Sedona',
    color: COLORS.green,
  },
  {
    slug: 'santa-cruz',
    name: 'Santa Cruz',
    color: COLORS.orange,
  },
  {
    slug: 'lake-tahoe',
    name: 'Lake Tahoe',
    color: COLORS.purple,
  },
  {
    slug: 'michigan-upper-peninsula',
    name: 'Michigan’s Upper Peninsula',
    color: COLORS.green,
  },
];
