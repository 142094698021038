import React from 'react';
import clsx from 'clsx';

import Router from 'lib/router';
import { useAuthUser, useUserPermissions } from 'lib/hooks/auth';
import { signOut } from 'lib/auth';
import Link, { NavLink } from 'components/Link';
import ListingsSearchBar from 'components/ListingsSearchBar';
import { useNotifications } from 'lib/notificationSubscription';

import BaseNav from './Base';

const logout = () => signOut().then(() => Router.push('/'));

export const NotifDot = ({ style }) => {
  return <span className="navbar-item__notif-dot" aria-label="Action required" style={style} />;
};

export const AccountItem = ({ userId }) => {
  const isAdmin = useUserPermissions().includes('admin');

  if (!userId) return null;

  return (
    <div className="navbar-item has-dropdown is-hoverable">
      <div className="navbar-link">Account</div>
      <div className="navbar-dropdown is-right is-boxed">
        <Link className="navbar-item" href={`/users/${userId}`}>
          Profile
        </Link>
        {/* <hr className="navbar-divider" /> */}
        <Link className="navbar-item" href="/settings/profile">
          Settings
        </Link>
        <Link className="navbar-item" href="/saved">
          Saved
        </Link>
        <Link className="navbar-item" href="/help">
          Help
        </Link>
        {isAdmin && (
          <>
            <hr className="navbar-divider" />
            <Link className="navbar-item" href="/admin">
              <span aria-hidden>&#128274;</span> Admin
            </Link>
          </>
        )}
        <hr className="navbar-divider" />
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events */}
        <a className="navbar-item" role="button" tabIndex={0} onClick={logout}>
          Logout
        </a>
      </div>
    </div>
  );
};

const NavbarEnd = ({ isLight }) => {
  const authUser = useAuthUser();
  const { messageCount, guestNotifCount, hostNotifCount } = useNotifications();

  if (authUser)
    return (
      <>
        <NavLink className="navbar-item" href="/host" exact>
          Host
          {hostNotifCount > 0 && <NotifDot />}
        </NavLink>
        <NavLink className="navbar-item" href="/trips">
          Trips
          {guestNotifCount > 0 && <NotifDot />}
        </NavLink>
        <NavLink className="navbar-item" href="/chats">
          Messages
          {messageCount > 0 && <NotifDot />}
        </NavLink>
        <AccountItem userId={authUser.uid} />
      </>
    );

  return (
    <>
      <span className="navbar-item">
        <Link
          href="/host"
          className={clsx('button is-outlined has-text-link', !isLight && 'is-inverted')}
        >
          Become a host
        </Link>
      </span>
      <Link className="navbar-item" href="/sign_up">
        Sign Up
      </Link>
      <Link className="navbar-item" href="/login">
        Login
      </Link>
    </>
  );
};

const MainNav = (props) => (
  <BaseNav
    navbarBrand={
      <span className="navbar-item" style={{ flexShrink: 1 }}>
        <ListingsSearchBar className="c-listing-search" />
      </span>
    }
    navbarEnd={NavbarEnd}
    {...props}
  />
);

export default MainNav;
