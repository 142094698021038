import { differenceInDays } from 'date-fns';
import * as _ from 'lodash-es';

import { MAX_REVIEW, RANDOMIZE_LOCATION_METERS } from 'lib/constants/listings';
import db, { C } from 'lib/db/shared';
import { MOBILE_APP } from 'lib/env';
import { getTimeZone } from 'lib/geo';
import { AVATAR_IMAGE_SIZES, LISTING_IMAGE_SIZES, buildAvatarImageUrl } from 'lib/images';
import { request } from 'lib/request';
import * as payments from 'lib/stripe';
import { gtagConversionEvent, trackError, trackEvent } from 'lib/tracking';
import { getPublicProfile, missingPublicProfile } from 'lib/userProfile';
import { isSubset, parseNum } from 'lib/utils';
import { dbCache, localCache } from 'lib/utils/cache';
import { randomAnnulusPoint } from 'lib/utils/distance';
import { throwForbidden } from 'lib/utils/errors';
import {
  addRelations,
  getAllDocs,
  getDoc,
  getDocs,
  queryExists,
  updateDoc,
} from 'lib/utils/firestore';
import { cleanPersonNames } from 'lib/utils/string';
import { now } from 'lib/utils/time';

const { Firestore, buckets, batch, getUser } = db;

export { C, getPublicProfile }; // TODO: remove

/**
 * Callable functions
 *
 * TODO: move somewhere else
 */

const callableFunction = (method, url) => async (body) => {
  return request({ url, method, body });
};

export const contactHelp = callableFunction('POST', '/api/support/new');

export const createUser = callableFunction('POST', '/api/users');

export const cloneListingImages = callableFunction('POST', '/api/clone_listing_images');

export const apiUpdateBooking = (bookingId, data) =>
  request({ url: `/api/bookings/${bookingId}`, method: 'PUT', body: data });

export const apiCreateBooking = callableFunction('POST', '/api/bookings');

export const apiUpdateUser = (data) =>
  request({ url: `/api/users/${getUser().uid}`, method: 'PUT', body: data });

export const createInstallAttribution = callableFunction('POST', '/api/install_attribution');

/**
 * Database methods
 */

export const randomDbId = () => C.meta.doc().id;

const storageFileMetadata = (storageRef) => storageRef.getMetadata().catch(() => null);

/**
 * Waits for file to exist on Firebase Storage
 */
const waitForStorageFile = async (storageRef, timeout = 6000) => {
  const startAt = Date.now();
  // eslint-disable-next-line no-constant-condition
  while (true) {
    if (await storageFileMetadata(storageRef)) return true;

    if (Date.now() - startAt >= timeout) return false;
    await new Promise((r) => setTimeout(r, 500));
  }
};

/**
 * @param {{
 * bucketId: keyof typeof buckets;
 * fileDir: string;
 * file: File;
 * waitForResized?: string | null;
 * progress?: (progress: number) => void;
 * }}
 */
export const uploadBucketImage = async ({
  bucketId,
  fileDir,
  file,
  waitForResized = null,
  progress = null,
}) => {
  /** @type {import('firebase/storage').} */
  const bucketRef = buckets[bucketId];

  const uploadTask = bucketRef.child(`${fileDir}/original`).put(file, { contentType: file.type });

  if (progress)
    uploadTask.on(db.firebase.storage.TaskEvent.STATE_CHANGED, (taskSnap) => {
      progress(((waitForResized ? 0.5 : 1) * taskSnap.bytesTransferred) / taskSnap.totalBytes);
    });

  await uploadTask;

  if (waitForResized) {
    const created = await waitForStorageFile(bucketRef.child(`${fileDir}/${waitForResized}`));

    progress?.(1.0);

    return created;
  }

  return true;
};

export const getPrivateProfile = (uid, options) => getDoc(C.users.doc(uid), options);

export const getUserPermissions = async (userId) => {
  try {
    const data = await getDoc(C.admins.doc(userId), { fail: false });
    return data?.permissions || [];
  } catch {}

  return [];
};

/**
 * @param {import('lib/constants/userPermissions').UserPermission[]} permissions
 */
export const hasUserPermission = async (userId, ...permissions) => {
  if (!userId) return false;

  const userPerms = await getUserPermissions(userId);

  return isSubset(permissions, userPerms);
};

export const updatePrivateProfile = (data) => updateDoc(C.users.doc(getUser().uid), data);

export const updatePublicProfile = async (userId, data) => {
  const {
    foundUsFrom,
    foundUsFromOther,
    avatarData,
    // for now, you can't save `name` directly, use `firstName` & `lastName` instead
    name: _n,
    firstName,
    lastName,
  } = data;

  const saveData = _.pick(data, 'work', 'bio', 'location');

  if (foundUsFrom) {
    await C.user_source_attributions.add({
      source: foundUsFrom,
      other: foundUsFromOther || null,
      user_id: userId,
      created_at: now(),
    });
  }

  const avatarFile = avatarData?.file;
  if (avatarFile) {
    const avatarId = randomDbId();

    const successfulResize = await uploadBucketImage({
      bucketId: 'avatarImages',
      fileDir: `${userId}/${avatarId}`,
      file: avatarFile,
      waitForResized: AVATAR_IMAGE_SIZES.default,
      checkPreviousChanged: true,
    });

    saveData.avatar = buildAvatarImageUrl(
      userId,
      avatarId,
      successfulResize ? 'default' : 'original',
    );

    saveData.avatarId = avatarId;

    if (!successfulResize) saveData.fdrz = true;
  }

  if (firstName || lastName) {
    Object.assign(saveData, cleanPersonNames(firstName, lastName));
  }

  await updateDoc(C.profiles.doc(userId), saveData);

  return getDoc(C.profiles.doc(userId));
};

export const setOnboarded = async (userId) => {
  await updateDoc(C.users.doc(userId), { onboarded: true });
};

const uploadListingImageFile = async (listingId, file, description) => {
  const userId = getUser().uid;
  const imageId = randomDbId();

  const successfulResize = await uploadBucketImage({
    bucketId: 'listingImages',
    fileDir: `${userId}/${listingId}/${imageId}`,
    file,
    waitForResized: LISTING_IMAGE_SIZES.default,
  });

  const m = { id: imageId, description };

  if (!successfulResize) m.fdrz = true;

  return m;
};

// TODO: allow multiple fcm token uploads if the user uses multiple devices!
export const setFcmToken = async (token) => {
  const authUser = getUser();
  if (!authUser) return;

  try {
    await localCache.fetch(`last_fcm_token:${authUser.uid}:${token || ''}`, async () => {
      // wait for user doc to exist. This is necessary since we call `setFcmToken`
      // right after user signup but before our backend creates user docs
      await getDoc(C.users.doc(authUser.uid), { wait: true, timeout: 6000 });

      await updateDoc(C.users.doc(authUser.uid), {
        [MOBILE_APP ? 'mobile_fcm_token' : 'fcm_token']: token || null,
      });
    });
  } catch (err) {
    trackError(err, 'setFcmToken', { token });
  }
};

export const savePhoneNumber = async (userId, phone) => {
  await updateDoc(C.users.doc(userId), { phone: phone || null });
  await updateDoc(C.profiles.doc(userId), { verified_phone: !!phone });
};

export const createProductFeedback = async (
  userId,
  isHost,
  additionalFeedback,
  recommendRating,
  testimonial,
) => {
  await C.product_feedbacks.add({
    is_host: isHost ?? null,
    additional_feedback: additionalFeedback || null,
    recommend_rating: recommendRating ?? null,
    testimonial: testimonial || null,
    user_id: userId || null,
    created_at: now(),
  });
};

export const hasUserVerifiedPhone = async (userId) => {
  const profile = await getDoc(C.profiles.doc(userId), { fail: false });
  return !!profile?.verified_phone;
};

const uploadListingImages = async (listingId, imageFiles) => {
  const promises = [];
  const cloneImages = [];

  let originalListingId;
  for (const image of imageFiles) {
    const { id, file, description } = image;
    // HACK
    if (image.originalListingId) originalListingId = image.originalListingId;

    if (id && originalListingId) {
      cloneImages.push({ id, description });
    } else if (file instanceof File) {
      promises.push(
        uploadListingImageFile(listingId, file, description).catch((err) => {
          trackError(err);
          return null;
        }),
      );
    } else {
      trackError('uploadListingImages: Invalid element in imageFiles:', image);
    }
  }

  if (cloneImages.length && originalListingId) {
    promises.push(
      cloneListingImages({
        from: originalListingId,
        to: listingId,
        images: cloneImages.map((im) => im.id),
      })
        .then(() => cloneImages)
        .catch((err) => {
          trackError(err);
          return null;
        }),
    );
  }

  return _.flatten(await Promise.all(promises)).filter(Boolean);
};

/**
 * naively randomize lat & lng, so we can display them to the public
 */
const createApproximateGeocoords = async (point) => {
  // move in random direction, somewhere between `min` and `max` meters
  return randomAnnulusPoint(point, RANDOMIZE_LOCATION_METERS.min, RANDOMIZE_LOCATION_METERS.max);
};

const computeListingUpdate = async (userId, data) => {
  const publicData = _.pick(
    data,
    'title',
    'description',
    'type',
    'size',
    'angle',
    'status',

    'city',
    'zipCode',
    'state',
    'country',

    'rules',

    'bookingNoticeLimit',
    'bookingNoticeLimitTime',
    'howFarCanGuestsBook',
    'nightsMin',
    'nightsMax',
    'checkIn',
    'checkOut',

    'unavailableDays',

    'price',
    'weekly_discount',
    'monthly_discount',
    'cancellationPolicy',
  );

  if (data.amenities) {
    publicData.amenities = _.transform(data.amenities, (acc, val, key) => {
      if (val === true) acc[key] = true;
      else {
        const num = parseNum(val);
        // a price of 0 is included as non-optional:
        if (num === 0) acc[key] = true;
        else if (num != null && num > 0) acc[key] = num;
      }
    });
  }

  if (data.experiences) {
    publicData.experiences = data.experiences;
  }

  if ('allowOneClickBookings' in data) {
    publicData.allowOneClickBookings = !!data.allowOneClickBookings;

    // Disable one-click-bookings if host has not yet verified their phone number
    if (publicData.allowOneClickBookings && !(await hasUserVerifiedPhone(userId)))
      publicData.allowOneClickBookings = false;
  }

  if (data.geocoords) {
    let timezone;
    try {
      timezone = (await getTimeZone(data.geocoords)).timeZoneId;
    } catch (err) {
      trackError(err, 'getTimezone error during listing update');
    }
    publicData.timezone = timezone || 'America/Los_Angeles';

    const approxCoords = await createApproximateGeocoords(data.geocoords);
    publicData.geocoords = approxCoords;
  }

  const privateData = _.pick(
    data,
    'street',
    'street_number',
    'address',
    'geocoords',
    'checkin_instructions',
  );

  return {
    publicData,
    privateData,
  };
};

export const createListing = async (data, duplicateOfListingId = null) => {
  const userId = getUser().uid;

  const created = now();
  const userRef = C.users.doc(userId);
  const listingId = randomDbId();

  const { publicData, privateData } = await computeListingUpdate(userId, data);

  if (Array.isArray(data.imageDatas)) {
    publicData.photos = await uploadListingImages(listingId, data.imageDatas);
  }

  const writeBatch = batch();

  writeBatch.update(userRef, { isLister: true });

  const metaData = {
    owner: userRef,

    created,
    updated: created,
  };

  writeBatch.set(C.listings.doc(listingId), {
    ...publicData,

    status: publicData.photos.length > 0 ? 'active' : 'inactive',
    duplicateOf: duplicateOfListingId || null,

    ...metaData,
  });

  writeBatch.set(C.private_listings.doc(listingId), {
    ...privateData,
    ...metaData,
  });

  await writeBatch.commit();

  trackEvent('create_listing', { listing_id: listingId, price: data.price });

  gtagConversionEvent('IlugCKyCuPMBEOu4vs8B');

  return listingId;
};

export const createListingReview = async (userId, listingId, data) => {
  const doc = await C.listing_reviews(listingId).add({
    created: now(),
    user_id: userId,
    ...data,
  });

  return doc.id;
};

const uploadListingReviewFile = async (file, userId, listingId, reviewId) => {
  const fileId = randomDbId();

  await uploadBucketImage({
    bucketId: 'listingReviewFiles',
    fileDir: `${userId}/${listingId}/${reviewId}/${fileId}`,
    file,
  });

  const m = { id: fileId, type: file.type };

  return m;
};

/**
 * @param {string} listingId
 * @param {{ name: string, preview:string, file: File }[]} files New photos array
 */
export const uploadListingReviewFiles = async (filesToUpload, listingId, reviewId) => {
  const userId = getUser().uid;
  const promises = [];
  for (const f of filesToUpload) {
    const { file } = f;

    if (file instanceof File) {
      promises.push(uploadListingReviewFile(file, userId, listingId, reviewId));
    } else {
      trackError('uploadListingReviewFiles: invalid element in files:', file);
    }
  }

  const files = await Promise.all(promises);

  await updateDoc(C.listing_reviews(listingId).doc(reviewId), { files });
};

export const createGuestReview = async (userId, guestId, data) => {
  const doc = await C.profile_reviews(guestId).add({
    created: now(),
    user_id: userId,
    ...data,
  });

  return doc.id;
};

export const getReviewOfGuest = async (guestId, bookingId) =>
  (await C.profile_reviews(guestId).where('booking_id', '==', bookingId).limit(1).get()).docs[0]
    ?.id || null;

export const getReviewOfListing = async (listingId, bookingId) =>
  (await C.listing_reviews(listingId).where('booking_id', '==', bookingId).limit(1).get()).docs[0]
    ?.id || null;

export const getListingSyncedCalendars = async (listingId) =>
  getDocs(C.synced_calendars.where('listing_id', '==', listingId));

export const createListingSyncedCalendar = async (syncedCalData) => {
  const userId = getUser().uid;

  const data = {
    user_id: userId,
    created: now(),
    ...syncedCalData,
  };

  try {
    const syncedCalRef = await C.synced_calendars.add(data);

    return syncedCalRef.id;
  } catch (err) {
    trackError(err);
    return null;
  }
};

export const deleteListingSyncedCalendar = async (syncedCalId) => {
  await C.synced_calendars.doc(syncedCalId).delete();
};

// EDIT LISTING

/**
 * Sets the listing's photos to the `newPhotos` array. If you are adding
 * a new photo, set that element in the `newPhotos` array to that image's `File`.
 *
 * NOTE: this function does not delete old listing images, because a booking may still be referencing that image
 * @param {string} listingId
 * @param {{ id: string, file: File }[]} newPhotos New photos array
 */
export const updateListingImages = async (listingId, newPhotos) => {
  const promises = [];

  for (const el of newPhotos) {
    const { id, file, description } = el;

    if (file instanceof File) {
      promises.push(uploadListingImageFile(listingId, file, description));
    } else if (id) {
      // NOOP
      promises.push(Promise.resolve({ id, description }));
    } else {
      trackError('updateListingImages: invalid element in newPhotos:', el);
    }
  }

  const photos = await Promise.all(promises);

  await updateDoc(C.listings.doc(listingId), { photos });
};

export const editListing = async (listingId, data) => {
  const { publicData, privateData } = await computeListingUpdate(getUser().uid, data);

  const writeBatch = batch();
  const updated = now();

  if (!_.isEmpty(publicData)) {
    writeBatch.update(C.listings.doc(listingId), {
      ...publicData,
      updated,
    });
  }

  if (!_.isEmpty(privateData)) {
    writeBatch.update(C.private_listings.doc(listingId), {
      ...privateData,
      updated,
    });
  }

  await writeBatch.commit();
};

export const getGroupedBookings = async (booking) => {
  const baseQuery = C.bookings
    .where('booker', '==', C.users.doc(booking.booker.id))
    .where('status', '==', 'pending')
    .select();

  let bookings;
  if (booking.grouped_booking_id) {
    bookings = [
      // get other `pending` bookings in this "group"
      ...(await getDocs(baseQuery.where('grouped_booking_id', '==', booking.grouped_booking_id))),
      // get original booking, only if `pending`
      ...(await getDocs(
        baseQuery.where(Firestore.FieldPath.documentId(), '==', booking.grouped_booking_id),
      )),
    ];
  } else {
    // get `pending` bookings in this "group"
    bookings = await getDocs(baseQuery.where('grouped_booking_id', '==', booking.id));
  }

  return _.uniq(
    _.without(
      bookings.map((b) => b.id),
      booking.id,
    ),
  );
};

export const getPrivateListing = async (listingId) => {
  const privateListing = await getDoc(C.private_listings.doc(listingId));

  return privateListing;
};

export const getLastNotification = async (userId, type) => {
  const notifications = await getDocs(
    C.notifications(userId).where('type', '==', type).orderBy('created_at', 'desc').limit(1),
  );
  return notifications[0] || null;
};

export const lastNotificationDaysAgo = async (userId, type) => {
  const lastNotification = await getLastNotification(userId, type);

  return lastNotification
    ? differenceInDays(new Date(), lastNotification.created_at.toDate())
    : null;
};

export const getMyListings = async (uid) => {
  const userRef = C.users.doc(uid);

  const [myListings, myBookings] = await Promise.all([
    getDocs(C.listings.where('owner', '==', userRef)),
    getDocs(C.bookings.where('lister', '==', userRef)),
  ]);

  const listingMap = {};

  const data = {};

  for (const listing of myListings) {
    listing.bookings = [];
    listingMap[listing.id] = listing;

    const status = listing.status;

    if (!data[status]) data[status] = [];
    data[status].push(listing);
  }

  for (const booking of myBookings) {
    listingMap[booking.listing.id].bookings.push(booking);
  }
  const noww = Date.now();
  const futureBookings = myBookings.filter((booking) => booking.start_at.toMillis() >= noww);

  await Promise.all(
    futureBookings.map(async (booking) => {
      booking.guest = await getPublicProfile(booking.booker.id);
    }),
  );

  return {
    activeListings: data.active || [],
    inactiveListings: data.inactive || [],
    futureBookings,
  };
};

export const getListingReviews = async (listingId, limit = 10) => {
  const reviews = await getDocs(
    C.listing_reviews(listingId).limit(limit).orderBy('created', 'desc'),
  );

  await Promise.all(
    reviews.map(async (review) => {
      review.author = await getPublicProfile(review.user_id);
    }),
  );

  return reviews;
};

export const getOwnedListings = async (uid) => {
  const userRef = C.users.doc(uid);

  const listingsDocs = await getDocs(C.listings.where('owner', '==', userRef));

  return { listings: listingsDocs };
};

export const getListingsWithBookings = async (uid) => {
  const userRef = C.users.doc(uid);

  const [listings, privateListings, myBookings] = await Promise.all([
    getDocs(C.listings.where('owner', '==', userRef)),
    getDocs(C.private_listings.where('owner', '==', userRef)),
    getDocs(C.bookings.where('lister', '==', userRef)),
  ]);

  const bookings = await addRelations(myBookings, {
    guest: [C.profiles, 'booker.id', missingPublicProfile],
  });

  const listingMap = {};

  for (const listing of listings) {
    listing.privateData = privateListings.find((p) => p.id === listing.id);

    listing.bookings = [];
    listingMap[listing.id] = listing;
  }

  for (const booking of bookings) {
    listingMap[booking.listing.id]?.bookings.push(booking);
  }

  return listings;
};

export const getListings = async (uid) => {
  const userRef = C.users.doc(uid);

  const listings = await getDocs(
    C.listings.where('owner', '==', userRef).where('status', '==', 'active'),
  );

  return listings;
};

export const getGuestBookings = async (uid) => {
  const userRef = C.users.doc(uid);

  const bookings = await getDocs(
    C.bookings.where('booker', '==', userRef).orderBy('created', 'desc'),
  );

  return bookings;
};

export const getHostBookings = async (uid) => {
  const userRef = C.users.doc(uid);
  const bookings = await getDocs(C.bookings.where('lister', '==', userRef));

  return bookings;
};

export const getListingBookings = async (uid, listingId) => {
  // const listingRef = C.listings.doc(listingId);
  const userRef = C.users.doc(uid);

  let listingBookings = await getDocs(
    C.bookings
      // Have to start with this first query to adhere to security rules
      .where('lister', '==', userRef)
      .where('listing.id', '==', listingId),
  );

  listingBookings = await addRelations(listingBookings, {
    guest: [C.profiles, 'booker.id', missingPublicProfile],
  });

  return listingBookings;
};

export const getBookingForUser = async (uid, bookingId) => {
  const booking = await getDoc(C.bookings.doc(bookingId));
  const isHost = booking.lister.id === uid;
  const isGuest = booking.booker.id === uid;
  if (!isHost && !isGuest) return throwForbidden();

  const listing = booking.listing;

  let reviewId = null,
    reviewLink = null;

  let otherUser = null;
  let vehicle = null;
  if (isGuest) {
    reviewId = await getReviewOfListing(listing.id, bookingId);
    if (reviewId) reviewLink = `/listings/${listing.id}#review-${reviewId}`;
  } else {
    reviewId = await getReviewOfGuest(booking.booker.id, bookingId);
    if (reviewId) reviewLink = `/users/${booking.booker.id}#review-${reviewId}`;
  }

  try {
    otherUser = await getPublicProfile(isHost ? booking.booker.id : booking.lister.id);
  } catch (err) {
    trackError(err, 'getBookingForUser: getPublicProfile', { bookingId, uid });
  }

  const guestPhone =
    isHost && booking.status === 'confirmed'
      ? (await getDoc(C.users.doc(booking.booker.id), { fail: false }))?.phone
      : null;

  try {
    vehicle = await getDoc(C.vehicles(booking.booker.id).doc(booking.vehicle));
  } catch (err) {
    trackError(err, 'getBookingForUser: getVehicle', { bookingId, uid });
  }

  return {
    booking,
    isHost,
    otherUser,
    guestPhone,
    vehicle,
    reviewId,
    reviewLink,
  };
};

export const getListing = async (listingId, ownerUid = null) => {
  const listing = await getDoc(C.listings.doc(listingId), { fail: false });

  if (!listing || (listing.status !== 'active' && (!ownerUid || listing.owner.id !== ownerUid))) {
    return null;
  }

  return listing;
};

export const getUserReviews = async (userId) => {
  return addRelations(await getDocs(C.profile_reviews(userId)), {
    author: [C.profiles, 'user_id', missingPublicProfile],
  });
};

export const getVehicles = async (userId) => {
  return getDocs(C.vehicles(userId).where('deleted_at', '==', null));
};

export const deleteVehicle = async (vehicleId) => {
  const userId = getUser().uid;

  await updateDoc(C.vehicles(userId).doc(vehicleId), { deleted_at: now() });

  await updateDoc(C.profiles.doc(userId), (profile) => {
    if (profile.default_vehicle === vehicleId) {
      return { default_vehicle: null };
    }
    return null;
  });
};

export const createVehicle = async (data) => {
  const userId = getUser().uid;

  data = {
    created: now(),
    deleted_at: null,
    ...data,
    length_ft: Number(data.length_ft),
  };

  try {
    const vehicleRef = await C.vehicles(userId).add(data);

    return vehicleRef.id;
  } catch (err) {
    trackError(err);
    return null;
  }
};

export const setVehicleAsDefault = async (vehicleId) => {
  const userId = getUser().uid;

  await updateDoc(C.profiles.doc(userId), { default_vehicle: vehicleId || null });
};

export const updateVehicle = async (vehicleId, vehicleInfo) => {
  const userId = getUser().uid;

  if (!vehicleId) throw new Error('Missing vehicleId');

  vehicleInfo = { ...vehicleInfo };

  if (vehicleInfo.length_ft) vehicleInfo.length_ft = Number(vehicleInfo.length_ft);

  delete vehicleInfo.id; // just in case

  await updateDoc(C.vehicles(userId).doc(vehicleId), vehicleInfo);
};

export const canReceivePayouts = async (userId) => {
  const stripeAccount = await payments.findStripeAccount(userId);
  return stripeAccount?.canAcceptPayouts;
};

export const getBalance = async (userId) => {
  const stripeAccount = await payments.findStripeAccount(userId);

  if (!stripeAccount || !stripeAccount.canAcceptPayouts) return null;

  const { available } = await payments.stripe.balance.retrieve(
    {},
    { stripeAccount: stripeAccount.accountData.stripe_account_id },
  );
  const usdBalances = available.filter((b) => b.currency === 'usd');
  const balance = usdBalances[0];

  return balance;
};

// NOTE: returns a float in range [0.0, 4.0]
export const getAverageListingRating = async (listingId) => {
  const averageRating = await dbCache.fetch(
    `average-listing-rating:${listingId}`,
    async () => {
      const validReviews = (await getDocs(C.listing_reviews(listingId)))
        .map((r) => r.general)
        .filter((review) => review != null && review >= 0 && review <= MAX_REVIEW);
      if (validReviews.length === 0) return null;

      return _.round(_.mean(validReviews), 2);
    },
    { expireIn: '1 hour' },
  );

  return averageRating;
};

export const getAverageUserRating = async (userId) => {
  const reviews = await getUserReviews(userId);

  const userRatings = reviews
    .map((r) => r.general)
    .filter((r) => r != null && r >= 0 && r <= MAX_REVIEW);

  const averageRating = _.round(_.mean(userRatings), 2);

  return averageRating;
};

export const createOrDeleteSavedListing = async (userId, listingId, addLike) => {
  const existingLike = (
    await getDocs(
      C.saved_listings.where('listing_id', '==', listingId).where('user_id', '==', userId).limit(1),
    )
  )[0];

  if (existingLike) {
    if (addLike) return true;
    else {
      // delete like
      try {
        await C.saved_listings.doc(existingLike.id).delete();
        return true;
      } catch (err) {
        trackError(err, 'Failed to delete liked listing', { userId, listingId, addLike });
        return false;
      }
    }
  } else {
    // create like
    try {
      await C.saved_listings.add({
        listing_id: listingId,
        user_id: userId,
        created_at: now(),
      });
      return true;
    } catch (err) {
      trackError(err, 'Failed to created liked listing', { userId, listingId, addLike });
      return false;
    }
  }
};

export const didUserLikeListing = async (userId, listingId) => {
  const existingLike = await getDocs(
    C.saved_listings.where('listing_id', '==', listingId).where('user_id', '==', userId).limit(1),
  );
  return existingLike.length > 0;
};

export const getListingsLikedByUser = async (userId) => {
  const likes = await getDocs(C.saved_listings.where('user_id', '==', userId));

  const listings = (
    await getAllDocs(...likes.map((like) => C.listings.doc(like.listing_id)))
  ).filter((l) => l.status === 'active');

  return listings;
};

export const getCoupon = async (code) => {
  if (!code) return null;

  const coupon = (await getDocs(C.coupons.where('code', '==', code).limit(1)))[0];
  if (!coupon) return null;

  return coupon;
};

export const isCouponApplicable = async ({
  created = Date.now(),
  code,
  buyer_id,
  listing_id,
  total,
}) => {
  const success = false;

  const coupon = await getCoupon(code);

  if (!coupon) return { success, error: 'Invalid coupon' };

  // Check if the user already used the coupon
  // THOUGHT: this should only check if it was successfully used
  // so if it's used for CONFIRMED / COMPLETED bookings
  // but then what if a user just books a bunch of listings
  // and then they all get approved (the user can still
  // book them since they're pending)
  const userAlreadyUsed = await queryExists(
    C.bookings.where('coupon', '==', code).where('booker', '==', C.users.doc(buyer_id)),
  );
  if (userAlreadyUsed) return { success, error: 'Coupon used already' };

  const { percentage, dollars } = coupon;

  const expired = coupon.expires_at.toMillis() < created;

  const { user, listing, minimum } = coupon.restrictions;

  if (expired || !coupon.active) return { success, error: 'Coupon expired' };
  if (user && user !== buyer_id) return { success, error: 'Invalid coupon' };
  if (listing && listing !== listing_id) return { success, error: 'Invalid coupon' };
  if (minimum && minimum > total) return { success, error: 'Invalid coupon' };

  if (
    (coupon.type === 'percentage' && coupon.percentage) ||
    (coupon.type === 'dollars' && coupon.dollars) ||
    (coupon.type === 'percentage_with_cap' && coupon.dollars && coupon.percentage)
  )
    return {
      success: true,
      error: null,
      percentage: percentage || undefined,
      dollars: dollars || undefined,
    };
  else return { success, error: 'Invalid coupon' };
};

export const getPhotographerProfile = async (userId) =>
  getDocs(C.photographer_profiles.where('user_id', '==', userId));

export const createPhotographerProfile = async (photographerProfile) => {
  const userId = getUser().uid;

  const d = await getPhotographerProfile(userId);
  if (d.length !== 0) {
    trackError('User already has photographer profile');
    return null;
  }

  const data = {
    user_id: userId,
    created: now(),
    ...photographerProfile,
  };

  try {
    const photographerRef = await C.photographer_profiles.add(data);

    return photographerRef.id;
  } catch (err) {
    trackError(err);
    return null;
  }
};

// --- END OF DATABASE METHODS ---
