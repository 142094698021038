import * as _ from 'lodash-es';
import {
  FirebaseAuthentication,
  SignInResult,
  SignInWithOAuthOptions,
} from '@capacitor-firebase/authentication';
import type firebase from 'firebase/compat/app';

import { throwNotLoggedIn } from 'lib/utils/errors';
import { BehaviorSubject, waitForNext } from 'lib/utils/async';
import { trackError, setTrackingUser, trackEvent, gtagConversionEvent } from 'lib/tracking';
import { isValidEmail } from 'lib/utils/string';
import { localCache } from 'lib/utils/cache';
import { HOST, IS_PROD_DATA, IS_SSR, MOBILE_APP, PLATFORM } from 'lib/env';
import { request, setAuthTokenHeader } from 'lib/request';
import db, { browserDb } from 'lib/db/shared';
import { encodeQuery } from 'lib/utils/url';

const prodWarn = (msg: string) => {
  if (IS_PROD_DATA) trackError(msg, undefined, 'warning');
  else throw new Error(msg);
};

const { Auth, auth } = browserDb;

export const getUser = db.getUser;

const authChangeSubject = new BehaviorSubject<firebase.User | null | undefined>(undefined);
const tokenChangeSubject = new BehaviorSubject<boolean | undefined>(undefined);

const AUTH_USER_STORAGE_KEY = 'auth_user';

// TODO: actually use this info
// const getClientConfig = () => {
//   let timezone = null;
//   try {
//     if (typeof Intl !== 'undefined')
//       timezone = Intl.DateTimeFormat().resolvedOptions().timeZone || null;
//   } catch {}

//   return {
//     timezone_offset: -new Date().getTimezoneOffset?.() ?? null,
//     timezone,
//   };
// };

const setServerAuthToken = async (token: string | null) => {
  if (MOBILE_APP) {
    // send user token as HTTP Header for mobile app
    setAuthTokenHeader(token);
  } else {
    // save user token as browser cookie for web
    try {
      await request({
        url: '/api/auth_token',
        method: token ? 'POST' : 'DELETE',
        body: token ? { token } : undefined,
      });
    } catch (err) {
      if (err.status === 400 && getUser()) {
        await auth.signOut().catch(() => {});
      }
      throw err;
    }
  }
};

export const initAuth = () => {
  if (IS_SSR) return;

  // TODO: we could uncomment this if we only wanted to use the cookie
  // for auth persistence (instead of `firebase/auth` using localStorage)
  // auth.setPersistence(Auth.Auth.Persistence.NONE);

  auth.languageCode = 'en';

  auth.onIdTokenChanged(async (authUser) => {
    tokenChangeSubject.next(undefined);

    try {
      if (!authUser) {
        await setServerAuthToken(null);
      } else {
        const token = await authUser.getIdToken();

        await setServerAuthToken(token);
      }
    } catch (err) {
      trackError(err, 'refreshUserToken');
    }

    tokenChangeSubject.next(true);
  });

  auth.onAuthStateChanged(
    (authUser) => {
      authChangeSubject.next(authUser);

      setTrackingUser(authUser);

      // TODO-loginpass: we should probably being using cookie instead of localStorage???
      // but static pages don't get the cookie...
      if (authUser)
        localCache.set(
          AUTH_USER_STORAGE_KEY,
          _.pick(authUser, [
            'uid',
            'email',
            'displayName',
            'phoneNumber',
            'photoURL',
            'emailVerified',
          ]),
        );
      else localCache.remove(AUTH_USER_STORAGE_KEY);
    },
    (err) => {
      trackError(err as unknown as Error, 'onAuthStateChanged');

      authChangeSubject.next(null);
    },
  );
};

// Must call this function after react initial render
export const createPhoneRecaptcha = (element: HTMLElement) =>
  new Auth.RecaptchaVerifier(element, { size: 'invisible' });

export const getCachedAuthUser = () => {
  if (IS_SSR) return null;

  try {
    const user = localCache.get(AUTH_USER_STORAGE_KEY) as firebase.User | null;
    if (user && typeof user === 'object' && user.uid && typeof user.uid === 'string') return user;
  } catch {}

  return null;
};

export const waitForLogin = async (enforceLoggedIn = false) => {
  if (IS_SSR) {
    prodWarn('waitForLogin called on server');
    return null;
  }

  await waitForNext(authChangeSubject);

  const authUser = getUser();

  if (enforceLoggedIn && !authUser) return throwNotLoggedIn();

  return authUser || null;
};

export const waitForAuthTokenSync = async () => {
  if (IS_SSR) {
    prodWarn('waitForAuthTokenSync called on server');
    return;
  }

  await waitForNext(tokenChangeSubject);
};

// NOTE: the scopes should match those in `capacitor.config.ts`
export const AUTH_PROVIDERS = [
  {
    name: 'Google',
    key: 'google.com',
    Class: Auth?.GoogleAuthProvider,
    socialIcon: 'google',
    buttonStyles: undefined,
    scopes: undefined,
    platforms: undefined,
  },
  {
    name: 'Facebook',
    key: 'facebook.com',
    Class: Auth?.FacebookAuthProvider,
    socialIcon: 'facebook',
    buttonStyles: {
      color: 'white',
      background: '#3b5998',
    },
    scopes: ['email'],
    platforms: undefined,
  },
  {
    name: 'Apple',
    key: 'apple.com',
    Class: null,
    socialIcon: 'apple',
    buttonStyles: {
      color: '#fff',
      background: '#333',
    },
    scopes: ['email', 'name'],
    platforms: ['ios'] as typeof PLATFORM[],
  },
] as const;

export const PLATFORM_AUTH_PROVIDERS = AUTH_PROVIDERS.filter(
  (ap) => !ap.platforms || ap.platforms.includes(PLATFORM),
);

export const getAuthStatusForEmail = async (email: string) => {
  let methods: string[] | undefined;
  try {
    methods = await auth.fetchSignInMethodsForEmail(email);
  } catch (err) {
    // TODO-loginpass: handle rate-limitting errors?
    await trackError(err, 'getAuthStatusForEmail', { email });
  }

  if (!methods?.length) return { exists: false };

  return {
    exists: true,
    usesPassword: methods.includes(Auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD),
    usesEmailLink: methods.includes(Auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD),
  };
};

export const sendWelcomeEmail = async (userId: string, fromPath?: string) => {
  await request({
    url: '/api/auth/send_welcome_email',
    method: 'POST',
    body: { user_id: userId, from: fromPath },
  });
};

export const sendResetPasswordEmail = async (
  email: string,
  missingPassword?: boolean,
  fromPath?: string,
) => {
  await request({
    url: '/api/auth/send_reset_password_email',
    method: 'POST',
    body: { email, missing_password: missingPassword, from: fromPath },
  });
};

export const authenticateUser = async ({
  type,
  isNewUser,
  loginToken,
  email,
  password,
  // phone,
  emailLink,
  providerId,
}: {
  type: 'email_password' | 'email_link' | 'phone' | 'provider_popup' | 'login_token';
  isNewUser?: boolean;
  loginToken?: string;
  email?: string;
  password?: string;
  // phone?: string;
  emailLink?: string;
  providerId?: typeof PLATFORM_AUTH_PROVIDERS[number]['key'];
}) => {
  let userCred: SignInResult | firebase.auth.UserCredential | undefined;

  if (type === 'email_link') {
    if (!email || !isValidEmail(email)) throw new Error('Cannot determine your email address');

    if (!emailLink || !auth.isSignInWithEmailLink(emailLink))
      throw new Error('Invalid signin link');

    userCred = await auth.signInWithEmailLink(email, emailLink);
    // } else if (type === 'phone') {
    //   phone = cleanPhone(phone);
    //   if (!isValidPhone(phone)) throw new Error('Cannot determine your phone number');
    //   if (!isValidEmail(email)) throw new Error('Cannot determine your email address');

    //   throw new Error('Not implemented');
  } else if (type === 'email_password') {
    if (!email || !isValidEmail(email)) throw new Error('Cannot determine your email address');
    if (!password) throw new Error('Cannot determine your password');

    if (isNewUser) {
      // throws error if account with email already exists
      // throws error if password is too weak
      userCred = await auth.createUserWithEmailAndPassword(email, password);
    } else {
      userCred = await auth.signInWithEmailAndPassword(email, password);
    }
  } else if (type === 'provider_popup') {
    const provConfig = AUTH_PROVIDERS.find((p) => p.key === providerId);
    if (!provConfig) throw new Error(`Unsupported provider: ${providerId}`);

    // TODO: throw an error if account doesn't exist and trying to sign in with provider

    if (MOBILE_APP) {
      try {
        const providerOpts: SignInWithOAuthOptions = {
          scopes: provConfig.scopes as string[] | undefined,
        };

        let credential;
        switch (providerId) {
          case 'google.com': {
            const r = await FirebaseAuthentication.signInWithGoogle(providerOpts);
            if (!r.credential?.idToken) throw new Error('Google sign in is missing token');
            credential = Auth.GoogleAuthProvider.credential(r.credential.idToken);
            break;
          }
          case 'facebook.com': {
            const r = await FirebaseAuthentication.signInWithFacebook(providerOpts);
            if (!r.credential?.accessToken)
              throw new Error('Facebook sign in is missing access token');
            credential = Auth.FacebookAuthProvider.credential(r.credential.accessToken);
            break;
          }
          case 'apple.com': {
            const r = await FirebaseAuthentication.signInWithApple(providerOpts);
            if (!r.credential?.idToken) throw new Error('Apple sign in is missing id token');
            credential = new Auth.OAuthProvider('apple.com').credential({
              idToken: r.credential.idToken,
              rawNonce: r.credential.nonce,
            });
            break;
          }
          default:
            throw new Error(`Unsupported provider: ${providerId}`);
        }

        userCred = await auth.signInWithCredential(credential);
      } catch (err) {
        // TODO-mobileapp: sometimes sign in errors from the native layer aren't user friendly

        // Google status code: SIGN_IN_CANCELLED
        // https://developers.google.com/android/reference/com/google/android/gms/auth/api/signin/GoogleSignInStatusCodes#public-static-final-int-sign_in_cancelled
        if (err.message.includes('12501')) {
          err.meta = { originalMessage: err.message };
          err.message = 'Google sign in was cancelled';
        }

        // prevent error number from showing up
        if (err.message && /Google Sign In failure.\s*\d+/.test(err.message)) {
          err.meta = { originalMessage: err.message };
          err.message = 'Google sign in failed';
        }
        throw err;
      }
    } else {
      const ProviderClass = provConfig.Class;
      if (!ProviderClass) throw new Error(`Unsupported provider class: ${providerId}`);

      const provider = new ProviderClass();

      for (const scope of provConfig.scopes || []) provider.addScope(scope);

      userCred = await auth.signInWithPopup(provider);
    }
  } else if (type === 'login_token') {
    if (!loginToken) throw new Error('Cannot determine your login token');
    userCred = await auth.signInWithCustomToken(loginToken);
  } else {
    throw new Error(`Unsupported sign in type: ${type}`);
  }

  if (!userCred?.user || !getUser()) {
    throw new Error('Failed to login!');
  }

  // wait for: save session cookie right after login
  await waitForAuthTokenSync();

  if (!getUser()) {
    throw new Error('Failed to login');
  }

  if (userCred.additionalUserInfo?.isNewUser) {
    isNewUser = true;
    trackEvent('sign_up', { method: providerId || type });

    gtagConversionEvent('B2DbCND5t_MBEOu4vs8B');
  } else {
    trackEvent('login', { method: providerId || type });
  }

  email = userCred.user.email || email;
  if (!email) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    signOut();
    throw new Error(
      'This account has no email address. Please sign in with a different method or reach out to our support team.',
    );
  }

  return {
    isNewUser,
    uid: userCred.user.uid,
    email,
  };
};

export const onAuthStateChanged = (cb: (user: firebase.User | null | undefined) => void) => {
  const sub = authChangeSubject.subscribe(cb);
  return () => sub.unsubscribe();
};

const assertUser = () => {
  const authUser = getUser();
  if (!authUser) throw new Error('Not logged in');
  return authUser;
};

export const unlinkAuthProvider = async (providerId: string) => {
  await assertUser().unlink(providerId);
};

export const sendVerifyChangeEmail = async (email: string) => {
  const authUser = assertUser();
  await authUser.verifyBeforeUpdateEmail(email, {
    url: `${HOST}/api/users/update_email_callback?${encodeQuery({
      user_id: authUser.uid,
    })}`,
  });
};

export const signOut = async () => {
  if (MOBILE_APP) {
    await FirebaseAuthentication.signOut();
    await auth.signOut(); // also needed for web
  } else {
    await auth.signOut();
  }

  // wait for: clear session cookie
  await waitForAuthTokenSync();
};

export const deleteAccount = async () => {
  await waitForLogin(true);

  await assertUser().delete();

  await signOut();
};
