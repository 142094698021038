export default {
  name: 'Vanly',
  moto: 'Camping, parking, & amenities for RVs & vanlife',
  description:
    "Find and book safe overnight parking and amenities across the US - let's share driveway space with Vanly.",
  theme_color: '#FA7C91',
  background_color: '#FFFFFF',
  icons: [
    {
      src: '/static/ic/solid-192x192.png',
      sizes: '192x192',
      type: 'image/png',
    },
    {
      src: '/static/ic/solid-512x512.png',
      sizes: '512x512',
      type: 'image/png',
    },
  ],
  start_url: '/',
  keywords: [
    'vanly',
    'overnight',
    'rv',
    'parking',
    'driveway',
    'rent',
    'vanlife',
    'sleep',
    'share',
    'vehicle',
  ],
};
