import React from 'react';
import { Share } from '@capacitor/share';
import { useRouter } from 'next/router';

import { PLATFORM } from 'lib/env';

import ListingsSearchBar from 'components/ListingsSearchBar';
import Link from 'components/Link';

import BaseNav from './Base';

export const MobileBackButton = ({ onClick, href, exitIcon }) => {
  const Tag = href ? Link : 'button';
  const icon = exitIcon ? <i className="fa fa-times" /> : <i className="fa fa-chevron-left" />;
  return (
    <Tag className="button is-light" {...(href ? { href } : { onClick })} aria-label="Go back">
      {icon}
    </Tag>
  );
};

/** @type {React.FC<{ shareInfo: import('@capacitor/share').ShareOptions }>} */
export const ShareButton = ({ shareInfo }) => {
  const handleShareBtnClick = async () => {
    // eslint-disable-next-line no-alert
    if (PLATFORM === 'web') window.alert(`Share info:\n${JSON.stringify(shareInfo, null, 2)}`);
    else {
      try {
        await Share.share(shareInfo);
      } catch (err) {
        if (err.message !== 'Share canceled') throw err;
      }
    }
  };

  return (
    <button
      type="button"
      className="button is-white has-text-grey"
      onClick={handleShareBtnClick}
      aria-label="Share"
    >
      <i className="fa fa-share-alt fa-lg" aria-hidden />
    </button>
  );
};

const MobileNav = ({
  hasSearchBar,
  returnTo,
  noBackButton,
  exitIcon,
  title,
  children,
  navBarEnd = null,
}) => {
  const router = useRouter();

  const hasHistory = typeof window !== 'undefined' && window.history.length > 0;
  const hasBackButton = !noBackButton && ((hasHistory && router.pathname !== '/') || !!returnTo);

  return (
    <BaseNav
      hideLogo
      navbarBrand={
        <>
          {hasBackButton ? (
            <div className="navbar-item">
              <MobileBackButton
                exitIcon={exitIcon}
                {...(returnTo || !hasHistory
                  ? { href: returnTo || '/' }
                  : { onClick: () => router.back() })}
              />
            </div>
          ) : null}
          <span
            className="navbar-item is-expanded"
            style={{ justifyContent: hasBackButton ? 'auto' : 'center' }}
          >
            {hasSearchBar ? (
              <ListingsSearchBar
                className="ListingsSearchBar--mobile-header"
                placeholder="Where to next?"
                iconColorClass="has-text-link"
                containerClassName="flex-1"
              />
            ) : null}
            <span className="has-text-dark has-text-weight-bold">{title}</span>
            {children}
          </span>
          {!!navBarEnd && <div className="navbar-item">{navBarEnd}</div>}
        </>
      }
    />
  );
};

export default MobileNav;
